import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { CardHeader, FormHelperText, OutlinedInput } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography'

import { IconInfo } from '../../assets/icons/FuncInfoIcon'

import {
    errorToast,
    successToast,
    phoneRegex
} from '../../constants.js/misc';

//MIXPANEL
import { Mixpanel } from '../../constants.js/mixpanel';

import {
    deleteUserStoreService,
    getUserStoreService,
    createUserStoreService,
    updateUserStoreService,
    getStoreLimits,
    getUserStoresService
} from '../../services/store';

import { routes } from '../../constants.js/misc';
import DeleteProductDialog from '../../components/ManageProduct/DeleteProductDialog/DeleteProductDialog';
import { colors } from '../../constants.js/colors';

/**
 * Renders the form for creating/updating a user store
 * @param {number} storeId main store id
 */
export const ManageUserStorePage = ({ storeId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams();

    const [gettingData, setGettingData] = useState(false);
    const [saving, setSaving] = useState(false);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [checkLimitUserStore, setCheckLimitUserStore] = useState(true);
    const [rows, setRows] = useState([]);

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        getValues
    } = useForm({
        mode: 'all',
    });

    const isValid = Object.keys(errors)?.length === 0;

    /**CHECK  LIMIT USER STORES */
    useEffect(() => {
        if (rows.length > 0) {
            getStoreLimits(storeId)
                .then(({ data }) => {
                    if (rows.length <= data.included_user_stores) {
                        setCheckLimitUserStore(false);
                    } else {
                        setCheckLimitUserStore(true);
                    }
                    setGettingData(false);
                })
                .catch(err => {
                    setGettingData(false);
                    enqueueSnackbar(t('UserStores.tableError'), errorToast)
                });
        } else {
            setCheckLimitUserStore(false);
        }
    }, [rows]);

    useEffect(() => {
        setGettingData(true);
        getUserStoresService(storeId, 10, 0)
            .then(res => {
                setRows(res.data);
            })
            .catch(err => {
                setGettingData(false);
                enqueueSnackbar(t('UserStores.tableError'), errorToast)
            });
    }, [storeId]);

    /**
     * Get the product data if an id is provided in the url
     */
    useEffect(() => {
        if (id) {
            setGettingData(true);
            getUserStoreService(storeId, id)
                .then(res => {
                    setGettingData(false);
                    setValue('name', res.data.name);
                    setValue('phone', res.data.mobile_phone_number);
                })
                .catch(err => {
                    setGettingData(false);
                    enqueueSnackbar(t('ManageUserStore.dataError'), errorToast);
                    history.push(routes.userStores);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    /**
     * Creates/Updates the user store
     * @param {Object} data user store data
     */
    const onSubmit = (data) => {
        const functionToCall = id ? updateUserStoreService : createUserStoreService;
        setSaving(true);
        functionToCall(storeId, {
            mobile_phone_number: data.phone,
            name: data.name,
            email: data.email
        }, id)
            .then(response => {
                Mixpanel.track('Agregar user store (sucursal)', {
                    "Crear nueva sucursal": "ir",
                    "Nombre de la sucursal": data.name,
                    "WS de la sucursal": data.phone
                });
                enqueueSnackbar(t('ManageUserStore.manageSuccess'), successToast);
                history.push(routes.userStores);
            })
            .catch(error => {
                enqueueSnackbar(t(error.response.data.message), errorToast);
                setSaving(false);
            })
    };

    /**
     * Deletes the selected user store
     */
    const deleteUserStore = () => {
        if (id) {
            setCancelDialogOpen(false);
            setSaving(true);
            deleteUserStoreService(storeId, id)
                .then(() => {
                    setSaving(false);
                    Mixpanel.track('Eliminar user Store (sucursal)', {
                        "Eliminar sucursal": "ir",
                        "Nombre de la sucursal": getValues('name'),
                        "WS de la sucursal": getValues('phone')
                    });
                    enqueueSnackbar(t('UserStore.deleteSuccess'), successToast);
                    history.push(routes.userStores);
                })
                .catch(() => {
                    setSaving(false);
                    enqueueSnackbar(t('UserStore.deleteError'), errorToast)
                })
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container>
                <Grid item container xs={12} style={{ marginBottom: '20px', justifyContent: 'space-between', display: 'flex' }}>
                    <div>
                        <Typography variant="h4">{id ? t('ManageUserStore.EditTitle') : t('ManageUserStore.Title')}</Typography>
                        <Link
                            component={RouterLink}
                            to={routes.userStores}
                        >
                            {t('UserStores.Title')}
                        </Link>
                    </div>
                    <div>
                        {(id && !gettingData && rows.length > 1) &&
                            <Button
                                disabled={saving}
                                variant="outlined"
                                color="secondary"
                                style={{ maxHeight: '43px', marginRight: '10px' }}
                                onClick={() => setCancelDialogOpen(true)}>
                                {t('ManageUserStore.deleteButton')}
                            </Button>
                        }
                        <Button
                            type="submit"
                            disabled={saving}
                            style={{ maxHeight: '43px' }}
                            color="primary"
                            variant="contained"
                        >
                            {saving
                                ?
                                <CircularProgress
                                    size={30}
                                    color="secondary"
                                />
                                :
                                t('ManageUserStore.save')
                            }
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '20px' }}>
                    <Typography variant="body2">{t('ManageUserStore.intro')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Card elevation={2} style={{ marginBottom: '21px' }}>
                        <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('ManageUserStore.cardTitle')} />
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FormControl>
                                        <FormLabel error={errors?.name ? true : false}>{t('ManageUserStore.nameField')}*</FormLabel>
                                        <Controller
                                            render={({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    variant="outlined"
                                                    defaultValue=""
                                                    error={errors.name ? true : false}
                                                    margin="normal"
                                                />
                                            )}
                                            defaultValue=""
                                            control={control}
                                            name="name"
                                            rules={{ required: true }}
                                        />
                                        <FormHelperText error={errors?.name ? true : false}>{errors?.name ? t('ManageUserStore.errors?.name') : ''}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} style={{ padding: '12px 12px 0' }}>
                                    <FormControl>
                                        <FormLabel error={errors?.phone ? true : false}>{t('ManageUserStore.phoneField')}*</FormLabel>
                                        <Controller
                                            render={({ field }) => (
                                                <OutlinedInput
                                                    {...field}
                                                    defaultValue=""
                                                    error={errors.phone ? true : false}
                                                    margin="normal"
                                                    placeholder='573120000000'
                                                />
                                            )}
                                            control={control}
                                            defaultValue=""
                                            name="phone"
                                            rules={{ required: true, pattern: phoneRegex }}
                                        />
                                        <div style={{ margin: '12px 0 0', display: 'flex', alignItems: 'center', gap: '5px' }}> 
                                            <IconInfo style={{ color: colors.primary.main }}/>
                                            <p style={{ margin: '0' }}>{t('ManageUserStore.info')} <b>+</b></p>
                                        </div>
                                       
                                        <FormHelperText error={errors?.phone ? true : false}>{errors?.phone ? t('ManageUserStore.errors?.phone') : ''}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {(id && !gettingData) &&
                <DeleteProductDialog
                    open={cancelDialogOpen}
                    onConfirm={deleteUserStore}
                    onClose={() => setCancelDialogOpen(false)}
                    title={t('UserStore.deleteTitle')}
                    message={t('UserStore.deleteMessage')}
                    cancelText={t('UserStore.deleteCancel')}
                    confirmText={t('UserStore.deleteConfirm')}
                />
            }
        </form>
    );
}

const mapStateToProps = ({ auth }) => ({
    storeId: auth.storeId
});

export default connect(mapStateToProps)(ManageUserStorePage);
