import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { AddCircle as AddCircleIcon } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ImageDefaultProduct from '../../assets/img/default/onboarding_products.jpg';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

import ViewDataEmpty from '../../components/ViewDataEmpty/ViewDataEmpty';
import FilterProductsSelect from '../../components/FilterProductsSelect/FilterProductsSelect';

import {
    getProductsService,
    updateProductService,
    getProductsServiceForStatus,
    getProductsServiceForSortName,
    getDownloadProducts
} from '../../services/products';

import {
    NameCell,
    AddNewProduct,
    ProductImage,
    ZoomContainer
} from './ProductsTablePage.styles';

import IconMasive from '../../assets/icons/upload-masive.svg';
import SearchIcon from '../../assets/icons/SearchIcon.svg';
import ExportIcon from '../../assets/icons/ExportIcon.svg';

import { setProductToEdit, setPageState, setRowsPerPageState } from '../../modules/products';
import { routes, formatNumber } from '../../constants.js/misc';
import useDebounce from '../../utils/debounce';
import { Input, InputAdornment } from '@material-ui/core';

const useStyles = makeStyles({
    root: { width: '100%' },
    container: {
        maxHeight: 'calc(100vh - 231px)',
    },
    spacer: {
        "& .MuiTablePagination-spacer": {
            flex: 0
        }
    },
    filterInput: {
        backgroundColor: '#fff',
        borderRadius: 8,
        boxShadow: '0px 0px 2px rgba(52, 58, 67, 0.1), 0px 1px 2px rgba(52, 58, 67, 0.08), 0px 1px 4px rgba(52, 58, 67, 0.08)',
        color: '#53627C',
        height: 42,
        padding: '14px 16px',
        width: '100%',
    }
});

const ProductsTablePage = ({
    storeId,
    setProductToEdit,
    initialRowsPerPage,
    initialPage,
    setPageState,
    setRowsPerPageState,
    storeData
}) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        { id: 'name', label: t('ProductsTable.headers.name'), minWidth: 170 },
        { id: 'sku', label: t('ProductsTable.headers.sku'), minWidth: 100 },
        { id: 'price', label: t('ProductsTable.headers.price'), minWidth: 100 },
        { id: 'edit', label: t('ProductsTable.headers.edit'), minWidth: 100 },
    ];

    const classes = useStyles();
    const [gettingData, setGettingData] = useState(true);
    const [page, setPage] = useState(initialPage);
    const [rows, setRows] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
    const [totalRows, setTotalRows] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [filter, setFilter] = useState('');
    const [selectedCategorie, setSelectedCategorie] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(0);
    const debouncedFilter = useDebounce(filter, 500);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setPageState(newPage);
    };

    const handleFilterChange = ({ target }) => setFilter(target.value);

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setPageState(0);
        setRowsPerPage(+event.target.value);
        setRowsPerPageState(+event.target.value);
    };

    const toggleProduct = (selectedProduct) => {
        setGettingData(true);
        const newActiveStatus = selectedProduct.active ? 0 : 1;
        updateProductService(storeId, {
            active: newActiveStatus
        }, selectedProduct.id, true)
            .then(() => {
                setGettingData(false);
                setRows(rows.map(product => ({
                    ...product,
                    active: selectedProduct.id === product.id ? newActiveStatus : product.active
                })));
                enqueueSnackbar(newActiveStatus ? t('ProductsTable.toggleSuccesActive') : t('ProductsTable.toggleSuccesInactive'), {
                    variant: 'success',
                    autoHideDuration: 2500
                });
            })
            .catch(err => {
                setGettingData(false);
                enqueueSnackbar(newActiveStatus ? t('ProductsTable.toggleErrorActive') : t('ProductsTable.toggleErrorInactive'), {
                    variant: 'error',
                    autoHideDuration: 2500
                });
                console.log(err)
            });
    }

    const filterCategoriesForProducts = (data) => {
        setPage(0);
        setRows(data.filter((item) => {
            return item.collections.some(e => e.collection_id === selectedCategorie.id);
        }));
    }

    const handleImageBase64 = (thumbnail) => {
        let image_src = JSON.stringify({
            "bucket": "wa10x.v2",
            "key": `${thumbnail}`,
            "edits": {
                "resize": {
                    "width": 600,
                    "height": 600,
                    "fit": "cover"
                },
                "rotate": null,
            }
        })

        return image_src;
    }

    const handleExportProduct = () => {
        getDownloadProducts(storeId)
    }

    useEffect(() => {
        setGettingData(true);
        const functionToCall = selectedStatus !== null ? getProductsServiceForStatus : selectedOrder ? getProductsServiceForSortName : getProductsService;
        functionToCall(storeId, rowsPerPage, page, debouncedFilter, selectedStatus, selectedOrder, selectedCategorie ? selectedCategorie.id : '')
            .then(res => {
                setTotalRows(+res.headers['x-total-count']);
                setRows(res.data || []);
                setGettingData(false);
                setFirstLoad(false);
            })
            .catch(err => {
                setGettingData(false);
                setFirstLoad(false);
                enqueueSnackbar(t('ProductsTable.error'), {
                    variant: 'error',
                    autoHideDuration: 2500
                })
            });
    }, [storeId, rowsPerPage, page, enqueueSnackbar, t, debouncedFilter, selectedCategorie, selectedStatus, selectedOrder]);

    return (
        <>
            {(gettingData && firstLoad) &&
                <Box
                    style={{ height: '100%' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                    <CircularProgress />
                </Box>
            }
            {rows.length === 0 && !gettingData && !selectedCategorie && selectedStatus !== 0 && !debouncedFilter ?
                <>
                    <ViewDataEmpty title="Agregar y gestionar tus productos"
                        subtitle="En tu ecometri puedes subir productos ilimitados para crear un catálogo completo de WhatsApp. Sube tus productos y agrégalos a tus categorías para que puedas organizarlos correctamente."
                        btnTitle={t('ProductsTable.addNew')}
                        action={routes.createProduct}
                        image={ImageDefaultProduct}>
                    </ViewDataEmpty>
                </>
                :
                <>
                    {(rows.length >= 0 && !firstLoad) &&
                        <>
                            <Grid container>
                                <Grid item container xs={4} style={{ marginBottom: '20px', justifyContent: 'flex-start', display: 'flex' }}>
                                    <Input
                                        className={classes.filterInput}
                                        disableUnderline
                                        color="primary"
                                        margin="normal"
                                        placeholder={t('Product.placeholderFilter')}
                                        onChange={handleFilterChange}
                                        value={filter}
                                        variant="outlined"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <img src={SearchIcon} alt="Search" />
                                            </InputAdornment>
                                        }
                                    />
                                </Grid>
                                <Grid item container xs={8} style={{ marginBottom: '20px', justifyContent: 'flex-end', display: 'flex' }}>
                                    <AddNewProduct>
                                        <Button
                                            size="small"
                                            style={{ marginRight: '16px' }}
                                            startIcon={<img src={ExportIcon} alt="" />}
                                            onClick={handleExportProduct}    
                                        >
                                            {t('ExportProducts.Title')}
                                        </Button>
                                        <Button
                                            size="small"
                                            style={{ marginRight: '16px' }}
                                            component={RouterLink}
                                            startIcon={<img src={IconMasive} alt="" />}
                                            to={routes.importProducts}>
                                            {t('ImportProducts.Title')}
                                        </Button>
                                        <Button
                                            component={RouterLink}
                                            to={routes.createProduct}
                                            color="primary"
                                            variant="contained"
                                            startIcon={<AddCircleIcon />}
                                        >
                                            {t('ProductsTable.addNew')}
                                        </Button>
                                    </AddNewProduct>
                                </Grid>
                                <Grid item container xs={12} style={{ marginBottom: '20px', justifyContent: 'flex-start', display: 'flex' }}>
                                    <FilterProductsSelect
                                        setSelectedOrder={setSelectedOrder}
                                        selectedOrder={selectedOrder}
                                        selectedStatus={selectedStatus}
                                        setSelectedStatus={setSelectedStatus}
                                        selectedCategorie={selectedCategorie}
                                        setSelectedCategorie={setSelectedCategorie}
                                    />
                                </Grid>
                            </Grid>
                            <Paper className={classes.root}>
                                <TableContainer className={classes.container}>
                                    <div style={{ minHeight: '4px' }}>
                                        {gettingData && <LinearProgress />}
                                    </div>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}>
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row, index) => (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.name + index}>
                                                    <NameCell>
                                                        {row.product_images.length > 0 &&
                                                            <ProductImage
                                                                src={`${process.env.REACT_APP_IMAGES_API_URL}${Buffer.from(handleImageBase64(row?.product_images[0]?.picture_url)).toString('base64')}`}
                                                                alt={row.name} 
                                                                isLast={index === rows.length - 1}
                                                            />
                                                        }
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <Link
                                                                color="textSecondary"
                                                                variant='button'
                                                                onClick={() => setProductToEdit(row)}
                                                                component={RouterLink}
                                                                to={'/products/update/' + row.id}>
                                                                {row.name}
                                                            </Link>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        disabled={gettingData}
                                                                        checked={Boolean(row.active)}
                                                                        onChange={() => toggleProduct(row)}
                                                                        name="active" />
                                                                }
                                                                label={row.active ? t('ProductsTable.deactivateProduct') : t('ProductsTable.activateProduct')} />
                                                        </div>
                                                    </NameCell>
                                                    <TableCell>
                                                        {row.sku}
                                                    </TableCell>
                                                    <TableCell>
                                                        {formatNumber(row.price, storeData.currency)}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={() => setProductToEdit(row)}
                                                            component={RouterLink}
                                                            to={'/products/update/' + row.id}>
                                                            {t('ProductsTable.headers.edit')}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    shape="rounded"
                                    variant="outlined"
                                    labelRowsPerPage={t('table.rowsPerPageLabel')}
                                    labelDisplayedRows={({ from, to, count }) => `${t('table.toShow')} ${to} ${t('table.of')} ${count} ${t('table.products')}`}
                                    rowsPerPageOptions={totalRows > 0 ? [10, 25, 50] : []}
                                    component="div"
                                    className={classes.spacer}
                                    count={totalRows}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </>
                    }
                </>
            }
        </>
    )
}

const mapStateToProps = ({ auth, products, common }) => ({
    isLoggingIn: auth.isLoggingIn,
    signUpData: auth.signUpData,
    storeId: auth.storeId,
    initialRowsPerPage: products.rowsPerPage,
    initialPage: products.page,
    storeData: common.storeData
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setProductToEdit,
    setPageState,
    setRowsPerPageState
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsTablePage);
