import { makeStyles } from '@material-ui/core/styles';

export const ProductClassStyle = makeStyles((theme) => ({
    item: {
        width: '50%',
        textDecoration: 'none!important',
        color: 'inherit'
    },
    imgProduct: {
        objectFit: 'cover',
        width: '100%',
        minHeight: 150
    },
   
    card: {
        margin: '0 0.5rem 1rem',
        overflow: 'hidden',
        borderRadius: 8,
        background: '#ffffff',
        boxShadow: '0px 0px 2px rgba(52, 58, 67, 0.10), 0px 2px 5px rgba(52, 58, 67, 0.08), 0px 5px 15px rgba(52, 58, 67, 0.08)',
        paddingBottom: '0.5rem'
    },
    image: {
        position: 'relative',
        margin: 0,
        lineHeight: 0,
        borderBottom: 'solid #f3f1f2 1px'
    },
    caption: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 'calc(100 % - 20px)',
        textAlign: 'left',
        margin: '0.5rem 0.5rem 0!important',
        color: 'rgba(15, 41, 48, 0.75)!important',
        lineHeight: '1.5!important'
    },

    priceContainer: {
        textAlign: 'left',
        padding: '0 0.5rem 0!important'
    },

    price: {
        display: 'inline-block',
        fontSize: '1rem!important',
        fontWeight: 'bold',
    },
    withDiscount: {
        marginLeft: '0.25rem!important',
        fontSize: '0.875rem!important',
        textDecoration: 'line-through',
        color: '#8A94A6',
    },
    discountPrice: {
        display: 'inline-block',
        fontSize: '1rem!important',
    },
    discountChip: {
        position: 'absolute',
        top: 8,
        left: 8,
        height: '16px!important',
        color: '#ffffff!important',
        fontSize: '0.625rem!important',
        letterSpacing: '0.25px',
        lineHeight: 1,
        backgroundColor: '#CDA35F!important'
    },
    spanChip: {
        overflow: 'visible',
        paddingLeft: 6,
        paddingRight: 6
    }
}));