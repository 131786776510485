import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { sortableElement, sortableHandle } from 'react-sortable-hoc';
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import GragIcon from '../../assets/icons/drag.svg'
import { IconEdit } from '../../assets/icons/FuncEditIcon'
import { IconDelete } from '../../assets/icons/funcDeleteIcons'

import { ImgPreviewProduct, LenghtProduct, IconDragCategories, IconsContainer } from './Collection.styles';
import { withStyles } from "@material-ui/core/styles";
import { deleteCollectionsService } from '../../services/collections';
import { errorToast } from '../../constants.js/misc'
import DeleteDialog from '../DeleteConfirmDialog/DeleteConfirmDialog'

import { colors } from '../../constants.js/colors'

const TextOnlyTooltip = withStyles({
    tooltip: {
        fontSize: 15
    }
})(Tooltip);

const DragHandle = sortableHandle((e) => <IconDragCategories src={GragIcon} onClick={(e) => e.stopPropagation()} style={{ cursor: 'pointer', float: 'right', margin: 20 }} />);

const Collection = sortableElement(({ 
    i, 
    idCollection, 
    defaultCheck, 
    imgCollection, 
    nameCollection, 
    totalProducts, 
    productsData, 
    storeId, 
    items, 
    setItems }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()

    const [openDialog, setOpenDialog] = useState(false)
    const [idToDelete, setIdToDelete] = useState(null)
    const [collectionToDelete, setCollectionToDelete] = useState(null)
    
    const defaultCol = items?.filter( col => col.default )

    const handleImageBase64 = (thumbnail) => {
        let image_src = JSON.stringify({
            "bucket": "wa10x.v2",
            "key": `${thumbnail}`,
            "edits": {
                "resize": {
                    "width": 600,
                    "height": 600,
                    "fit": "cover"
                },
                "rotate": null,
            }
        })

        return image_src;
    }

    const toEdit = (idCollection) => {
        history.push(`/edit-collection/${idCollection}`)
      }

    /**FUNCION PARA ELMINAR UNA COLECCION */
    const removeCollection = () => {
        const collectionsNew = items?.filter( col => col.id !== idToDelete )
        deleteCollectionsService(storeId, idToDelete, collectionToDelete)
            .then(({ data }) => {
                setOpenDialog(false)
                setItems(collectionsNew)
                enqueueSnackbar(`Colección eliminada exitosamente`, {
                    variant: 'success',
                    autoHideDuration: 2500
                });
            }).catch(err => {
                setOpenDialog(false);
                enqueueSnackbar(t('Collection.deleteError'), errorToast);
            });
    }

    useEffect( ()=>{
        if(idToDelete){
            const findCol = items.find( col => col.id === idToDelete )
            setCollectionToDelete(findCol)
        }
    }, [idToDelete] )

    return (
        <TextOnlyTooltip
            arrow
            title={defaultCheck ? "Esta categoría agrupa TODOS los productos y su nombre no puede alterarse. Sin embargo, puedes ocultarla/desactivarla si lo deseas." : ''}
            placement="top-start"
        >
            <Card elevation={0}
                style={{
                    background: '#FFFFFF',
                    borderRadius: '12px',
                    margin: 10,
                    width: 260,
                    justifyContent: 'center',
                    textAlign: 'center',
                    boxShadow: '0px 1px 4px rgba(52, 58, 67, 0.08), 0px 1px 2px rgba(52, 58, 67, 0.08), 0px 0px 2px rgba(52, 58, 67, 0.1)',
                }}
            >

                <div>
                    <Avatar
                        style={{
                            float: 'left',
                            margin: 10,
                            color: 'black',
                            backgroundColor: 'transparent',
                            boxShadow: '0px 0px 1px rgba(52, 58, 67, 0.75)'
                        }} >{i + 1}</Avatar>
                    <DragHandle />
                </div>



                {!imgCollection && (


                    <Avatar style={{
                        margin: '0px auto',
                        alignSelf: 'center',
                        width: 128,
                        height: 128,
                        marginTop: 25,
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        fontSize: '2.6rem',
                        objectFit: 'cover'
                    }}>{nameCollection.slice(0, 2)}</Avatar>

                )
                }
                {imgCollection && (


                    <Avatar style={{
                        margin: '0px auto',
                        alignSelf: 'center',
                        justifyContent: "center", display: "flex",
                        width: 128,
                        height: 128,
                        marginTop: 25,
                        objectFit: 'cover'
                    }} alt="Remy Sharp" src={process.env.REACT_APP_IMAGES_API_URL + 'fit-in/600x600/' + imgCollection} />
                )
                }
                <CardContent>
                    <Typography variant="h6" >
                        {nameCollection}
                    </Typography>
                        <IconsContainer>
                            <IconEdit
                                onClick={()=> toEdit(idCollection)}
                                style={{ cursor: 'pointer', width: '25px', height: '25px', fill: colors.primary.dark }}
                            />
                            { idCollection !== defaultCol[0]?.id &&
                                <IconDelete
                                    style={{ cursor: 'pointer', width: '25px', height: '25px', fill: colors.primary.dark }}
                                    onClick={() => {
                                        setIdToDelete(idCollection) 
                                        setOpenDialog(true) 
                                    }}
                                />
                            }
                        </IconsContainer>
                    <p style={{
                        height: 20
                    }}>
                        {totalProducts >= 1 ? 
                            (
                                productsData.map((data, index) => {
                                    return data.product_images.length > 0 ?
                                        <ImgPreviewProduct
                                            key={index}
                                            //src={process.env.REACT_APP_IMAGES_API_URL + 'fit-in/600x600/' + data.product_images[0].picture_url}
                                            src={`${process.env.REACT_APP_IMAGES_API_URL}${Buffer.from(handleImageBase64(data?.product_images[0]?.picture_url)).toString('base64')}`}

                                        >
                                        </ImgPreviewProduct>
                                        : ''
                                })
                            ) : 'Sin productos'}
                        {totalProducts > 3 && <LenghtProduct>+{Number(totalProducts - 3)}</LenghtProduct>
                        }
                    </p>

                </CardContent>
        
                <DeleteDialog
                    open={openDialog}
                    onConfirm={removeCollection}
                    onClose={() => setOpenDialog(false)}
                    title={t('Collection.deleteTitle')}
                    message={t('Collection.deleteMessage')}
                    cancelText={t('Product.deleteCancel')}
                    confirmText={t('Product.deleteConfirm')}
                />
            
            </Card >
        </TextOnlyTooltip>

    );
});

const mapStateToProps = ({ auth }) => ({
    storeId: auth.storeId
})
export default connect(
    mapStateToProps
)(Collection)

