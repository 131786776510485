import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, CardHeader } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import ws from '../../../assets/icons/whatsapp-primary-dark.svg';
import clientIcon from "../../../assets/img/clientIcon.png";
import EditOrderDataModal from '../EditOrderDataModal/EditOrderDataModal';
import { DOCUMENTS_TYPES } from '../../../constants.js/generals';

const OrderClientInfo = ({ client, editMode, setClientData }) => {
    const { t } = useTranslation();

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogClose = () => setDialogOpen(false);
    const handleDataUpdate = () => {
        handleDialogClose();
    }

    const openModal = () => setDialogOpen(true);

    const documentType = DOCUMENTS_TYPES[client?.document_type]?.name || '';

    return (
        <>
            <Card elevation={2} style={{ marginBottom: '21px' }}>
                <CardHeader
                    titleTypographyProps={{ variant: 'h6' }}
                    avatar={<img src={clientIcon} alt='clientIcon' />}
                    title={`${client?.first_name} ${client?.last_name}`}
                    style={{ padding: '20px 16px 13px 16px', textTransform: 'capitalize' }}
                />
                <CardContent style={{ padding: '0 16px 25px 16px' }}>
                    <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography gutterBottom variant="body1">{client?.email}</Typography>
                        <Typography gutterBottom variant="body1">
                            {t('OrderDetail.client.phone')} +{client?.phone_number}
                        </Typography>
                        <Typography gutterBottom variant="body1">
                            {t('OrderDetail.client.id')} {documentType} {client?.dni}
                        </Typography>
                        {!editMode
                            ? <Typography gutterBottom variant="body1" style={{ margin: '10px 0 0 0 0' }}>
                                <Link
                                    color='textSecondary'
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    target="_blank"
                                    href={`https://wa.me/${client?.phone_number}?text=Hola!`}>
                                    <>
                                        <img src={ws} alt={client?.phone_number} style={{ marginRight: '5px' }} />
                                        WhatsApp
                                    </>
                                </Link>
                            </Typography>
                            : <Button
                                fullWidth
                                type='button'
                                variant="contained"
                                onClick={openModal}
                                color="primary">
                                {t('OrderDetail.client.editBtn')}
                            </Button>
                        }
                    </Grid>
                </CardContent>
            </Card>
            <EditOrderDataModal
                dataToEdit={client}
                setData={setClientData}
                open={dialogOpen}
                isNoClose={false}
                handleClose={handleDialogClose}
                handleSuccess={handleDataUpdate}
                type={1}
            />
        </>
    );
};
export default connect()(OrderClientInfo);