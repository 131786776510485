import React, {useState} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';


import visibility from '../../assets/icons/visibility.svg'
import visibilityOff from '../../assets/icons/visibilityOff.svg'

import { RecoveryLinkPassword, SubmitButtonContainer } from '../SignUpForm/SignUpForm.style';

import { logIn } from '../../modules/auth';
import { emailRegex } from '../../constants.js/misc';

/**
 * Log in form component
 * @param {function} logIn log in function from the store
 * @param {boolean} isLoggingIn log in loader variable from the store
 * @param {function} handleLogin function to execute on login success
 */
const SignInForm = ({ logIn, isLoggingIn, onLogin }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { handleSubmit, control, formState, errors } = useForm({
        mode: "onChange"
    });

    const handleLoginError = () => enqueueSnackbar(t('LogIn.error'), {
        variant: 'error',
        autoHideDuration: 2500
    });

    const onSubmit = (data) => logIn(data.email, data.password, onLogin, handleLoginError);

    const [password, setPassword] = useState(false)

    const showPassword = () => {
        setPassword( prevState => !prevState )
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <FormControl
                    variant="outlined"
                    margin="normal"
                    fullWidth
                >
                    <FormLabel error={errors?.email ? true : false}>{t('LogIn.email')}</FormLabel>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                            <OutlinedInput
                                {...field}
                                error={errors?.email ? true : false}
                                fullWidth
                                placeholder={t('LogIn.emailPlaceholder')}
                                type="email"
                            />
                        )}
                        rules={{ required: true, pattern: emailRegex }}
                    />
                    <FormHelperText error={errors?.email ? true : false}>{errors?.email ? t('LogIn.emailError') : ''}</FormHelperText>
                </FormControl>
                <FormControl
                    variant="outlined"
                    margin="normal"
                    fullWidth
                >
                    <FormLabel error={errors?.password ? true : false}>{t('LogIn.password')}</FormLabel>
                    <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                            <OutlinedInput
                                {...field}
                                error={errors?.password ? true : false}
                                fullWidth
                                placeholder={t('LogIn.passwordPlaceholder')}
                                type={password ? "text" : "password"}
                            />
                        )}
                        rules={{ required: true }}
                    />
                    <img 
                        style={{ width:'25px', height: '25px', position: 'absolute', right: '15px', top: '33px', cursor: 'pointer'}}
                        src={password ? visibilityOff : visibility } 
                        onClick={showPassword}    
                    />
                    <FormHelperText error={errors?.password ? true : false}>{errors?.password ? t('LogIn.passwordError') : ''}</FormHelperText>
                </FormControl>
                {/* <FormControlLabel control={<Checkbox color="primary" name="checkedC" />} label={t('LogIn.rememberMe')} /> */}
                <RecoveryLinkPassword>
                    <Link variant='secondary' component={RouterLink} to="/password-recovery">{t('RecoveryPassWord.link')}</Link>
                </RecoveryLinkPassword>
                <SubmitButtonContainer>
                    <Button
                        color="secondary"
                        disabled={!formState.isValid || isLoggingIn}
                        type="submit"
                        size="medium"
                        variant="contained"
                        fullWidth>
                        {t('LogIn')}
                    </Button>
                    <Button
                        color="secondary"
                        size="medium"
                        variant="outlined"
                        fullWidth
                        component={RouterLink} to="/sign-up"
                        style={{ padding: '12px', fontSize: '18px'}}
                    >
                        {t('SignUp')}
                    </Button>
                </SubmitButtonContainer>
            </form>
            {/* <ActionItem>
                <Typography variant="body1" gutterBottom>
                    {t('LogIn.notUser')}
                    <Link component={RouterLink} to="/sign-up/user-info">{t('SignUp')}</Link>
                </Typography>
            </ActionItem> */}    
        </>
    );
};

const mapStateToProps = ({ auth }) => ({
    isLoggingIn: auth.isLoggingIn
});

const mapDispatchToProps = dispatch => bindActionCreators({ logIn }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignInForm);
