// React dependencies
import React, { useEffect, useState, useRef } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

// External dependencies
import { useSnackbar } from 'notistack';
import moment from 'moment'

// UI material dependencies
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    CircularProgress,
    ClickAwayListener,
    Divider,
    Grid,
    Grow,
    Input,
    InputAdornment,
    LinearProgress,
    Link,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ReplayIcon from '@material-ui/icons/Replay'
import { makeStyles } from '@material-ui/core/styles'

// Internal components
import ViewDataEmpty from '../../components/ViewDataEmpty/ViewDataEmpty'
import OrderProductsTable from '../../components/OrderInfoPage/OrderProductsTable/OrderProductsTable'

// Internal dependencies
import { errorToast, formatNumber, routes } from '../../constants.js/misc'
import { setOrderToEdit, setPageState, setRowsPerPageState } from '../../modules/orders'
import { getOrderFilters, getOrdersService } from '../../services/orders'
import ImageDefaultOrders from '../../assets/img/default/orders.jpg'

// Assets
import FilterIcon from '../../assets/icons/FilterIcon.svg'
import SearchIcon from '../../assets/icons/SearchIcon.svg'
import ExportIcon from '../../assets/icons/ExportIcon.svg'
import { statusShipping } from '../../constants.js/orders'

import { downloadOrders } from '../../services/orders'


const useStyles = makeStyles({
    root: { width: '100%' },
    container: {
        maxHeight: '570px',
    },
    newContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    containerDesktop: {
        maxHeight: '560px',
    },
    gridContainer: {
        "& div": {
            maxWidth: 'inherit',
            padding: '8px'
        },
    },
    btn: {
        "& .MuiButton-label": {
            fontSize: 14
        },
        "& .MuiButton-contained": {
            background: '#fff'
        }
    },
    btnFilter: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'center',
        margin: '8px 8px 0px 8px',

        "&:disabled": {
            backgroundColor: '#F6F6F6',
            color: '#8181A5',
        },
    },
    filterInput: {
        backgroundColor: '#fff',
        borderRadius: 8,
        boxShadow: '0px 0px 2px rgba(52, 58, 67, 0.1), 0px 1px 2px rgba(52, 58, 67, 0.08), 0px 1px 4px rgba(52, 58, 67, 0.08)',
        color: '#53627C',
        height: 42,
        padding: '14px 16px',
        width: '100%',
    }
});

const orderTypes = [
    {
        id: 1,
        type: 'WhatsApp'
    },
    {
        id: 2,
        type: 'Pago en línea'
    },
]

const paymentMethods = [
    {
        id: 1,
        type: 'PayU'
    },
    {
        id: 2,
        type: 'Mercado Pago'
    },
    {
        id: 3,
        type: 'Wompi'
    },
    {
        id: 4,
        type: 'Paypal'
    },
    {
        id: 6,
        type: 'Pago Manual'
    }
]

const ACTUAL_DAY = moment().format('YYYY-MM-DD')
const YESTERDAY = moment().subtract(1, 'days').format('YYYY-MM-DD')

/**
 * Renders the orders table
 * @param {number} storeId store id
 * @param {number} initialPage initial page to show
 * @param {number} initialRowsPerPage initial rows per page to show
 * @param {function} setOrderToEdit sets the orderToEdit object on the state
 * @param {function} setPageState sets the page to show on the state
 * @param {function} setRowsPerPageState sets the rows per page the state
 */
export const OrdersTablePage = ({
    storeId,
    initialPage,
    initialRowsPerPage,
    setOrderToEdit,
    setPageState,
    setRowsPerPageState,
    storeData
}) => {
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    const classes = useStyles()
    const history = useHistory()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' })

    // state variables
    const [gettingData, setGettingData] = useState(true)
    const [page, setPage] = useState(localStorage.getItem('page') ? parseFloat(localStorage.getItem('page')) : initialPage)
    const [rows, setRows] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(localStorage.getItem('rows') ? parseFloat(localStorage.getItem('rows')) : initialRowsPerPage)
    const [totalRows, setTotalRows] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true)
    const [apply, isApply] = useState(false);

    const [formatDate, setFormatDate] = useState('')

    const [filter, setFilter] = useState('');
    const [filterSale, setFilterSale] = useState('')

    const [statusOrder, setStatusOrder] = useState([])
    const [statusDelivery, setStatusDelivery] = useState([])

    const [selectedDate, setselectedDate] = useState('')
    const [selectedSale, setSelectedSale] = useState([])
    const [selectedOrder, setSelectedOrder] = useState([])
    const [selectedDelivery, setSelectedDelivery] = useState([])

    const [dataSale, setDataSale] = useState([])
    const [dataOrder, setDataOrder] = useState([])
    const [dataDelivery, setDataDelivery] = useState([])
    
    const [paymentId, setPaymentId] = useState()
    const [orderTypeId, setOrderTypeId] = useState()


    const [openDate, setOpenDate] = useState(false)
    const [openSale, setOpenSale] = useState(false)
    const [openOrder, setOpenOrder] = useState(false)
    const [openDelivery, setOpenDelivery] = useState(false)
    const [openPayment, setOpenPayment] = useState(false)
    const [openOrderType, setOpenOrderType] = useState(false)
    

    const anchorRefDate = useRef(null)
    const anchorRefSale = useRef(null)
    const anchorRefOrder = useRef(null)
    const anchorRefDelivery = useRef(null)
    const anchorRefPayment = useRef(null)
    const anchorRefOrderType = useRef(null)

    const paymentMethodSel = paymentMethods.find( p => p.id === paymentId)
    const orderTypeSel = orderTypes.find( o => o.id === orderTypeId )
    const dateOptions = ['Todas las fechas', 'Hoy', 'Ayer', 'Últimos 7 días', 'Últimos 30 días'];
    const columns = [
        { id: 'order', label: t('OrdersTablePage.headers.order'), mobile: true },
        { id: 'date', label: t('OrdersTablePage.headers.date'), mobile: true },
        { id: 'products', label: t('OrdersTablePage.headers.products'), mobile: true },
        { id: 'total', label: t('OrdersTablePage.headers.total'), mobile: true },
        { id: 'buyer', label: t('OrdersTablePage.headers.buyer') },
        { id: 'channel', label: t('OrdersTablePage.headers.channel') },
        { id: 'state', label: t('OrdersTablePage.headers.state') },
        { id: 'stateShipping', label: t('OrdersTablePage.headers.stateShipping') },
        { id: 'actions', label: t('OrdersTablePage.headers.actions') },
    ]

    const handleClickDate = () => {
        setOpenDate(openDate ? false : true);
    }

    const handleClickSale = () => {
        setOpenSale(openSale ? false : true);
    }

    const handleClickOrder = () => {
        setOpenOrder(openOrder ? false : true);
    }

    const handleClickDelivery = () => {
        setOpenDelivery(openDelivery ? false : true);
    }

    const handleClickPayment = () => {
        setOpenPayment(!openPayment)
    }
    const handleClickOrderType = () => {
        setOpenOrderType(!openOrderType)
    }

    const handleMenuItemClickDate = (option) => {
        handleFormatDate(option);
        setselectedDate(option);
        setPage(0);
        setOpenDate(false);
    };

    const handleFormatDate = (option) => {
        switch (option) {
            case 'Todas las fechas':
                setFormatDate('');
                break;
            case 'Hoy':
                setFormatDate(moment().format('YYYY-MM-DD'));
                break;
            case 'Ayer':
                setFormatDate(moment().subtract(1, 'days').format('YYYY-MM-DD'));
                break;
            case 'Últimos 7 días':
                setFormatDate(moment().subtract(7, 'days').format('YYYY-MM-DD'));
                break;
            case 'Últimos 30 días':
                setFormatDate(moment().subtract(30, 'days').format('YYYY-MM-DD'));
                break;
            default:
                setFormatDate('');
                break;
        }
    };

    const handleMenuItemClickSale = (option, id) => {
        const newSelectedSale = [...selectedSale];
        const index = newSelectedSale.findIndex(item => item.name === option);
        if (index === -1) {
            newSelectedSale.push({ name: option, id: id });
        } else {
            newSelectedSale.splice(index, 1);
        }
        setSelectedSale(newSelectedSale);
        setPage(0);
        setOpenSale(false);
    };

    const handleCloseDate = (event) => {
        if (anchorRefDate.current && anchorRefDate.current.contains(event.target)) {
            return;
        }
        setOpenDate(false);
    };

    const handleCloseSale = (event) => {
        if (anchorRefSale.current && anchorRefSale.current.contains(event.target)) {
            return;
        }
        //setOpenSale(false);
    };

    const handleCloseOrder = (event) => {
        if (anchorRefOrder.current && anchorRefOrder.current.contains(event.target)) {
            return;
        }
        setOpenOrder(false);
    };

    const handleCloseDelivery = (event) => {
        if (anchorRefDelivery.current && anchorRefDelivery.current.contains(event.target)) {
            return;
        }
        setOpenDelivery(false);
    }

    const handleCloseOrderType = (event) => {
        if (anchorRefOrderType.current && anchorRefOrderType.current.contains(event.target)) {
            return
        }
        setOpenOrderType(false)
    }

    const handleClosePayment = (event) => {
        if (anchorRefPayment.current && anchorRefPayment.current.contains(event.target)) {
            return
        }
        setOpenPayment(false)
    }

    const handleClickClearFilter = () => {
        setselectedDate('')
        setFormatDate('')
        setSelectedSale([])
        setSelectedOrder([])
        setSelectedDelivery([])
        setStatusOrder([])
        setStatusDelivery([])
        setPage(0)
        setOrderTypeId('')
        setPaymentId('')
    };

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
        setPageState(newPage);
        localStorage.setItem('page', newPage)
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setPageState(0);
        setRowsPerPage(+event.target.value);
        setRowsPerPageState(+event.target.value);
        localStorage.setItem('rows', +event.target.value)
    };

    const toggleProductsRow = (orderId) => setRows(rows.map((item, index) => ({
        ...item,
        showProducts: item.id === orderId ? !item.showProducts : item.showProducts
    })))

    const handleFilterChange = ({ target }) => {
        setFilter(target.value);
        setPage(0);
    }

    const handleCheckOrder = (check, status, name) => {
        check
            ? setSelectedOrder([...selectedOrder, { id: status, name: name }])
            : selectedOrder.find(item => item.id !== status) !== undefined ? setSelectedOrder([selectedOrder.find(item => item.id !== status)]) : setSelectedOrder([]);

        check
            ? setStatusOrder([...statusOrder, status])
            : statusOrder.splice(statusOrder.indexOf(status), 1);

        setPage(0);
    }

    const handleCheckDelivery = (check, status, name) => {
        check
            ? setSelectedDelivery([...selectedDelivery, { id: status, name: name }])
            : selectedDelivery.find(item => item.id !== status) !== undefined ? setSelectedDelivery([selectedDelivery.find(item => item.id !== status)]) : setSelectedDelivery([]);

        check
            ? setStatusDelivery([...statusDelivery, status])
            : statusDelivery.splice(statusDelivery.indexOf(status), 1);

        setPage(0);
    }

    const handleSaleChange = (e) => {
        setFilterSale(e.target.value);
    }

    const handleCheckPayment = (id) => {
        setPaymentId( id === paymentId ? '' : id) 
    }

    const handleCheckOrderType = (id) => {
        setOrderTypeId( id === orderTypeId ? '' : id)
    }

    const totalWithShipping = (orderShippingCost) => {
        return orderShippingCost
            ? (isNaN(orderShippingCost)
                ? ((isNaN(storeData.delivery_cost_details) || !storeData.delivery_cost_enabled) ? 0 : parseInt(storeData.delivery_cost_details))
                : orderShippingCost
            )
            : (isNaN(storeData.delivery_cost_details) || !storeData.delivery_cost_enabled) ? 0 : parseInt(storeData.delivery_cost_details);
    }

    useEffect(() => {
        setGettingData(true)
        getOrderFilters(storeId)
            .then(res => {
                setDataSale(res?.data?.user_stores)
                setDataOrder(res?.data?.order_status_counter)
                setDataDelivery(res?.data?.order_shipping_counter)
                setGettingData(false);
                setFirstLoad(false);
            })
            .catch(err => {
                console.log('Error OrdersTablePage', err);
                setGettingData(false);
                setFirstLoad(false);
            });
    }, [storeId]);

    useEffect(() => {
        setGettingData(true);
        isApply(false)
        getOrdersService(
            storeId,
            rowsPerPage,
            page,
            formatDate !== ''
                ? selectedDate !== 'Ayer' ? `${formatDate} 00:00:00,${ACTUAL_DAY} 23:59:59` : `${formatDate} 00:00:00,${YESTERDAY} 23:59:59`
                : '',
            selectedSale.length > 0 ? selectedSale.map(item => item.id).join(',') : '',
            selectedOrder.length > 0 ? selectedOrder.map(item => item.id).join(',') : '',
            selectedDelivery.length > 0 ? selectedDelivery.map(item => item.id).join(',') : '',
            filter.replace(/\+/g, ' ').trim(),
            orderTypeId,
            paymentId
        )
            .then(res => {
                setTotalRows(+res.headers['x-total-count'] || 12);
                setRows(res.data.map(item => ({
                    ...item,
                    showProducts: false
                })));
                setGettingData(false)
                setFirstLoad(false)
            })
            .catch(err => {
                console.log('Error OrdersTablePage', err);
                setGettingData(false)
                setFirstLoad(false)
                enqueueSnackbar(t('OrdersTablePage.tableError'), errorToast)
            });
    }, [storeId, rowsPerPage, page, enqueueSnackbar, t, formatDate, selectedSale, apply, filter])

    return (
        <Grid container>
            {(gettingData && firstLoad) &&
                <Box
                    style={{ height: '90vh', width: '100%' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                    <CircularProgress />
                </Box>
            }

            <Grid item xs={12} className={classes.newContainer}>
                <Grid item container xs={8} style={{ justifyContent: 'flex-center', display: 'flex' }}>
                    <Input
                        className={classes.filterInput}
                        disableUnderline
                        color="primary"
                        margin="normal"
                        placeholder={t('OrderDetail.placeholderSearch')}
                        onChange={handleFilterChange}
                        value={filter}
                        variant="outlined"
                        startAdornment={
                            <InputAdornment position="start">
                                <img src={SearchIcon} alt="Search" />
                            </InputAdornment>
                        }
                    />
                </Grid>
                {/*
                <Grid item container xs={2} style={{ marginBottom: '20px', justifyContent: 'flex-end', display: 'flex' }}>
                    <Button
                        size="small"
                        style={{ marginRight: '16px' }}
                        startIcon={<img src={ExportIcon} alt="" />}
                    >
                        {t('ExportList.title')}
                    </Button>
                </Grid>
                */}
                <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        className="btnLink"
                        onClick={() => history.push('/order/create')}
                        color="primary"
                        variant="contained"
                    >
                        {t('OrderDetail.createOrder')}
                    </Button>
                </Grid>
            </Grid>

            <Grid 
                item 
                container 
                xs={12} 
                style={{ 
                    display: 'flex', 
                    justifyContent: 'flex-start', 
                    marginBottom: 27,
                    flexDirection: 'column'
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <Typography
                        variant="body2"
                        style={{ margin: 8 }}
                    >
                        <img style={{ marginRight: 5 }} src={FilterIcon} alt="" />
                        {t('Product.filter.by')}
                    </Typography>
                    {/* LIMPIAR FILTROS */}
                    <div style={{ display: 'flex', gap: 15, marginBottom: 15 }}>
                        <Button
                            onClick={() => downloadOrders(storeId)}
                            variant='outlined'
                            color='secondary'
                            startIcon={<img src={ExportIcon} alt="Dowload icon" />}
                        >
                            Descargar pedidos
                        </Button>
                        <Button
                            onClick={handleClickClearFilter}
                            startIcon={<ReplayIcon />}
                            disabled={selectedDate !== '' || selectedSale?.length > 0 || selectedOrder.length > 0 || selectedDelivery.length > 0 || paymentId || orderTypeId ? false : true}
                        >
                            {t('Product.filter.empty')}
                        </Button>
                    </div>
                </div>
                <div className={isTabletOrMobile && classes.gridContainer}>
                    <ButtonGroup className={classes.btn} variant="contained" disableElevation={true}>
                        {/* FECHA */}
                        <Button
                            onClick={handleClickDate}
                            endIcon={<ExpandMoreIcon />}
                            ref={anchorRefDate}
                            aria-controls={openDate ? 'split-button-categorie' : undefined}
                            aria-expanded={openDate ? 'true' : undefined}
                        >
                            {selectedDate ? selectedDate : t('OrdersTablePage.filter.date')}
                        </Button>

                        {/* CANAL DE VENTA */}
                        <Button
                            onClick={handleClickSale}
                            endIcon={<ExpandMoreIcon />}
                            ref={anchorRefSale}
                            aria-controls={openSale ? 'split-button-menu' : undefined}
                            aria-expanded={openSale ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                        >
                            {selectedSale.length > 0
                                ? selectedSale.map(item => item?.name).join(', ')
                                : t('OrdersTablePage.filter.sale')
                            }
                        </Button>

                        {/* ESTADO DEL PEDIDO */}
                        <Button
                            onClick={handleClickOrder}
                            endIcon={<ExpandMoreIcon />}
                            ref={anchorRefOrder}
                            aria-controls={openOrder ? 'split-button-menu' : undefined}
                            aria-expanded={openOrder ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                        >
                            {selectedOrder.length > 0
                                ? selectedOrder.map(item => item?.name).join(', ')
                                : t('OrdersTablePage.filter.order')
                            }
                        </Button>

                        {/* ESTADO DEL ENVIO */}
                        <Button
                            onClick={handleClickDelivery}
                            endIcon={<ExpandMoreIcon />}
                            ref={anchorRefDelivery}
                            aria-controls={openDelivery ? 'split-button-menu' : undefined}
                            aria-expanded={openDelivery ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                        >
                            {selectedDelivery.length > 0
                                ? selectedDelivery.map(item => item?.name).join(', ')
                                : t('OrdersTablePage.filter.delivery')
                            }
                        </Button>
                        {/* TYPE ORDER */}
                        <Button
                            onClick={handleClickOrderType}
                            endIcon={<ExpandMoreIcon />}
                            ref={anchorRefOrderType}
                            aria-controls={openOrderType ? 'split-button-menu' : undefined}
                            aria-expanded={openOrderType ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                        >
                            { orderTypeSel ? orderTypeSel.type : t('OrdersTablePage.filter.payment') }
                        </Button>
                        {/* PAYMENT CHANNEL */}
                        <Button
                            onClick={handleClickPayment}
                            endIcon={<ExpandMoreIcon />}
                            ref={anchorRefPayment}
                            aria-controls={openPayment ? 'split-button-menu' : undefined}
                            aria-expanded={openPayment ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                        >
                            { paymentMethodSel ? paymentMethodSel.type: t('OrdersTablePage.filter.paymentChannel') }
                        </Button>
                    </ButtonGroup>
                </div>

                {/* POPPER DATE */}
                <Popper style={{ zIndex: 99 }} open={openDate} anchorEl={anchorRefDate.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                marginTop: 10,
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseDate}>
                                    <MenuList id="split-button-categorie">
                                        {dateOptions.map((option, index) => (
                                            <MenuItem
                                                key={option.id}
                                                onClick={(event) => handleMenuItemClickDate(option)}
                                            >
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>

                {/* POPPER SALE */}
                <Popper style={{ zIndex: 99 }} open={openSale} anchorEl={anchorRefSale.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                marginTop: 10,
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <Input
                                    type='number'
                                    className={classes.filterInput}
                                    style={{ margin: 12, width: '90%' }}
                                    disableUnderline
                                    color="primary"
                                    margin="normal"
                                    placeholder={t('OrderDetail.placeholderSaleChannel')}
                                    onChange={(e) => handleSaleChange(e)}
                                    value={filterSale}
                                    variant="outlined"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <img src={SearchIcon} alt="Search" />
                                        </InputAdornment>
                                    }
                                />
                                <ClickAwayListener onClickAway={handleCloseSale}>
                                    <MenuList id="split-button-menu">
                                        {dataSale.map((option, index) => (
                                            <MenuItem
                                                key={option?.id}
                                                onClick={(event) => handleMenuItemClickSale(option?.mobile_phone_number, option?.id)}
                                            >
                                                {option?.mobile_phone_number}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                 {/* POPPER ORDER */}
                <Popper style={{ zIndex: 99 }} open={openOrder} anchorEl={anchorRefOrder.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                marginTop: 10,
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseOrder}>
                                    <MenuList id="split-button-menu" style={{ display: 'flex', flexDirection: 'column' }}>
                                        {dataOrder.map((option, index) => (
                                            <React.Fragment>
                                                <Grid item container xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }} key={option?.status_id}>
                                                    <Checkbox
                                                        checked={statusOrder.includes(option?.status_id)}
                                                        onClick={
                                                            () => handleCheckOrder(
                                                                statusOrder.includes(option?.status_id) ? false : true,
                                                                option?.status_id,
                                                                option?.status_name
                                                            )
                                                        }
                                                    />
                                                    <MenuItem>
                                                        {`${option?.status_name} (${option?.quantity})`}
                                                    </MenuItem>
                                                </Grid>
                                                <Divider />
                                            </React.Fragment>
                                        ))}
                                        <Button
                                            onClick={() => isApply(true)}
                                            color="primary"
                                            variant="contained"
                                            className={classes.btnFilter}
                                            disabled={statusOrder.length === 0 ? true : false}
                                        >
                                            Aplicar
                                        </Button>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>                               
                {/* POPPER DELIVERY */}
                <Popper style={{ zIndex: 99 }} open={openDelivery} anchorEl={anchorRefDelivery.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                marginTop: 10,
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseDelivery}>
                                    <MenuList id="split-button-menu" style={{ display: 'flex', flexDirection: 'column' }}>
                                        {dataDelivery.map((option, index) => (
                                            <React.Fragment>
                                                <Grid item container xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }} key={option?.status_id}>
                                                    <Checkbox
                                                        checked={statusDelivery.includes(option?.status_id)}
                                                        onClick={
                                                            () => handleCheckDelivery(
                                                                statusDelivery.includes(option?.status_id) ? false : true,
                                                                option?.status_id,
                                                                option?.status_name
                                                            )
                                                        }
                                                    />
                                                    <MenuItem>
                                                        {`${option?.status_name} (${option?.quantity})`}
                                                    </MenuItem>
                                                </Grid>
                                                <Divider />
                                            </React.Fragment>
                                        ))}
                                        <Button
                                            onClick={() => isApply(true)}
                                            color="primary"
                                            variant="contained"
                                            className={classes.btnFilter}
                                            disabled={statusDelivery.length === 0 ? true : false}
                                        >
                                            Aplicar
                                        </Button>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>

                {/* POPPER ORDER TYPE */}
                <Popper style={{ zIndex: 99 }} open={openOrderType} anchorEl={anchorRefOrderType.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                marginTop: 10,
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseOrderType}>
                                    <MenuList id="split-button-menu" style={{ display: 'flex', flexDirection: 'column' }}>
                                        { orderTypes.map((option, index) => (
                                            <React.Fragment>
                                                <Grid item container xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }} key={option?.id}>
                                                    <Checkbox
                                                        checked={orderTypeId === option?.id}
                                                        onClick={ () => handleCheckOrderType(option?.id) }
                                                    />
                                                    <MenuItem>
                                                        {option?.type}
                                                    </MenuItem>
                                                </Grid>
                                                <Divider />
                                            </React.Fragment>
                                        ) ) }
                                        <Button
                                            onClick={() => isApply(true)}
                                            color="primary"
                                            variant="contained"
                                            className={classes.btnFilter}
                                            disabled={!orderTypeId}
                                        >
                                            Aplicar
                                        </Button>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>

                {/* POPPER PAYMENT CHANNEL*/}
                <Popper style={{ zIndex: 99 }} open={openPayment} anchorEl={anchorRefPayment.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                marginTop: 10,
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClosePayment}>
                                    <MenuList id="split-button-menu" style={{ display: 'flex', flexDirection: 'column' }}>
                                        {paymentMethods.map((option, index) => (
                                            <React.Fragment>
                                                <Grid item container xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }} key={option?.id}>
                                                    <Checkbox
                                                        checked={ paymentId === option?.id }
                                                        onClick={
                                                            () => handleCheckPayment(option?.id)
                                                        }
                                                    />
                                                    <MenuItem>
                                                        {option?.type} 
                                                    </MenuItem>
                                                </Grid>
                                                <Divider />
                                            </React.Fragment>
                                        ))}
                                        <Button
                                            onClick={() => isApply(true)}
                                            color="primary"
                                            variant="contained"
                                            className={classes.btnFilter}
                                            disabled={!paymentId}
                                        >
                                            Aplicar
                                        </Button>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>

            {
                rows.length === 0 && !gettingData
                    ?
                    <Grid item xs={12}>
                        <ViewDataEmpty title="Tus pedidos aparecerán acá"
                            subtitle="Los pedidos se crean cuando un cliente agrega artículos a su carrito de compra y completa el proceso hacia WhatsApp. Tan pronto un cliente realice un pedido se mostrará aquí."
                            image={ImageDefaultOrders}>
                        </ViewDataEmpty>
                    </Grid>
                    :
                    <React.Fragment>
                        {(rows.length >= 0 && !firstLoad) &&
                            <React.Fragment>
                                <Grid item xs={12} style={{ marginTop: 25 }}>
                                    <Paper className={classes.root}>
                                        <TableContainer className={isTabletOrMobile ? classes.container : classes.containerDesktop}>
                                            <div style={{ minHeight: '4px' }}>
                                                {gettingData && <LinearProgress />}
                                            </div>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        {columns.map((column) => (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                style={{
                                                                    minWidth: column.minWidth,
                                                                    display: isTabletOrMobile ? column.mobile ? 'table-cell' : 'none' : 'table-cell',
                                                                }}>
                                                                {column.label}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rows.map((row, index) => {
                                                        return (
                                                            <>
                                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                    <TableCell>
                                                                        <Link
                                                                            color="textSecondary"
                                                                            variant='button'
                                                                            onClick={() => setOrderToEdit(row)}
                                                                            component={RouterLink}
                                                                            to={routes.ordersPage + '/' + row.id}>
                                                                            #{row.sequence}
                                                                        </Link>
                                                                    </TableCell>
                                                                    <TableCell>{new Date(row.created_at).toLocaleDateString()}</TableCell>
                                                                    <TableCell style={{ cursor: 'pointer' }}>
                                                                        <Link 
                                                                        color="textSecondary"
                                                                        variant='button'
                                                                        onClick={() => toggleProductsRow(row.id)}>
                                                                            Ver {row.order_details.length} {row.showProducts ? '↑' : '↓'}
                                                                        </Link>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {formatNumber(row.order_details.reduce((acc, detail) => acc + (detail.price * detail.quantity), 0) + totalWithShipping(row.shipping_cost), storeData.currency)}
                                                                    </TableCell>
                                                                    {!isTabletOrMobile &&
                                                                        <>
                                                                            <TableCell>{row.customer.first_name} {row.customer.last_name}</TableCell>
                                                                            <TableCell>
                                                                                {row.user_store ?
                                                                                    <Link
                                                                                        color="textSecondary"
                                                                                        variant='button'
                                                                                        style={{ whiteSpace: 'nowrap' }}
                                                                                        component={RouterLink}
                                                                                        to={routes.updateUserStore + '/' + row.user_store.id}>
                                                                                        {row.user_store.name}
                                                                                    </Link> :
                                                                                    <Typography
                                                                                        variant="caption">
                                                                                        <em>Sucursal eliminada</em>
                                                                                    </Typography>
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>{row.order_status.name}</TableCell>
                                                                            <TableCell>{statusShipping[row.shipping_order_status_id]}</TableCell>
                                                                            <TableCell>
                                                                                <Button 
                                                                                    color="primary"
                                                                                    onClick={() => setOrderToEdit(row)}
                                                                                    style={{ whiteSpace: 'nowrap' }}
                                                                                    component={RouterLink} to={routes.ordersPage + '/' + row.id} variant="contained">{t('OrdersTablePage.headers.btnOrder')}</Button>
                                                                            </TableCell>
                                                                        </>
                                                                    }
                                                                </TableRow>
                                                                {row.showProducts &&
                                                                    <TableRow>
                                                                        <TableCell colSpan={isTabletOrMobile ? 4 : 9} style={{ padding: 0, background: '#F8F9FB' }}>
                                                                            <OrderProductsTable orderDetails={row.order_details} />
                                                                        </TableCell>
                                                                    </TableRow>
                                                                }
                                                            </>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            shape="rounded"
                                            variant="outlined"
                                            labelRowsPerPage={t('table.rowsPerPageLabel')}
                                            labelDisplayedRows={({ from, to, count }) => `${t('table.toShow')} ${to} ${t('table.of')} ${count} ${t('table.orders')}`}
                                            rowsPerPageOptions={totalRows > 0 ? [10, 25, 50] : []}
                                            component="div"
                                            count={totalRows}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                </Grid>
                            </React.Fragment>}
                    </React.Fragment>
            }
        </Grid >
    );
}

const mapStateToProps = ({ auth, orders, common }) => ({
    storeId: auth.storeId,
    initialRowsPerPage: orders.rowsPerPage,
    initialPage: orders.page,
    storeData: common.storeData
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setRowsPerPageState,
    setPageState,
    setOrderToEdit
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrdersTablePage);