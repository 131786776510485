import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  overrides: {
    MuiListItem: {
      root: {
        '&.Mui-selected': {
          backgroundColor: 'transparent',
          borderRight: '4px solid #9EE870',
        },
      },
    },
    MUIRichTextEditor: {
      root: {
        marginTop: 10,
      },
      editorContainer: {
        boxShadow: '0px 0px 2px rgba(52,58,67,0.10), 0px 1px 2px rgba(52,58,67,0.08), 0px 1px 4px rgba(52,58,67,0.08)',
        borderRadius: '8px',
        padding: '11.5px 14px'
      },
      hidePlaceholder: {
        display: 'block !important'
      }
    },
    MuiPaper: {
      rounded: {
        border: 'none',
        boxShadow: '0px 0px 1px rgba(52,58,67,0.75)',
      },
    },
    MuiButton: {
      containedPrimary: {
        '&.Mui-disabled': {
          backgroundImage: 'none',
        },
      },
      containedSizeLarge: {
        padding: '16px 24px',
        fontSize: '18px',
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: 'DM Sans',
        transition: 'color 150ms cubic-bezier(0.4, 0, 1, 1)',
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '8px',
      },
      root: {
        width: '100%'
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        padding: '0 !important'
      },
    },
    WAMuiChipInput: {
      inputRoot: {
        padding: '0 14px !important',
        boxShadow: '0px 0px 2px rgba(52,58,67,0.10), 0px 1px 2px rgba(52,58,67,0.08), 0px 1px 4px rgba(52,58,67,0.08)'
      },
      chip: {
        marginTop: '8px !important'
      },
      input: {
        boxShadow: 'none !important',
        padding: '11.5px 14px !important',
        paddingLeft: '0 !important',
        height: '17px !important',
        marginTop: '0'
      }
    },
    MuiOutlinedInput: {
      root: {
        'label + &': {
          marginTop: '0.5rem',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#9EE870',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#9EE870',
        },
      },
      adornedStart: {
        paddingLeft: '0px !important'
      },
      input: {
        padding: '11.5px 14px !important',
        borderRadius: 8,
        position: 'relative',
      },
      notchedOutline: {
        borderColor: '#dce3e8',
        transition: 'border 100ms cubic-bezier(0.4, 0, 1, 1)',
      },
      multiline: {
        padding: '0',
        textarea: {
          padding: '11.5px 14px',
        }
      }
    },
    MuiInputBase: {
    },
    MuiSelect: {
      selectMenu: {
        borderRadius: 8,
      },
      root: {
        '&:before': {
          content: 'none'
        },
        display: 'flex',
        alignItems: 'center',
        padding: '11.5px 14px !important',
        position: 'relative',
        'label + &': {
          marginTop: '0.25rem',
        },
      },
    },
    MuiLink: {
      root: {
        '&:focus': {
          outline: 'none',
          textDecoration: 'underline',
        },
      }
    }
  },
  palette: {
    type: 'light',
    primary: {
      light: '#9EE870',
      main: '#9EE870',
      dark: '#9EE870',
      contrastText: '#173300',
    },
    secondary: {
      light: '#1E1E1E',
      main: '#1E1E1E',
      dark: '#1E1E1E',
    },
    gray: {
      light: '#eeeeee',
      main: '#adadad',
      dark: '#6e6e6e',
      contrastText: '#53627c',
    },
    error: {
      light: '#d49494',
      main: '#cb7c7a',
      dark: '#a26362',
    },
    warning: {
      light: '#dab47d',
      main: '#cda35f',
      dark: '#b99356',
    },
    success: {
      light: '#73baa9',
      main: '#48ac98',
      dark: '#3a8a7a',
    },
    text: {
      primary: "#1B2524",
      secondary: "#173300",
      disabled: "#a6aebc",
      hint: "#a6aebc",
      white: "#ffffff",
    },
    background: {
      paper: '#ffffff',
      default: '#ffffff',
    },
  },
  shadows: [
    'none',
    '0px 0px 2px rgba(52,58,67,0.10),0px 1px 2px rgba(52,58,67,0.08),0px 1px 4px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 2px 5px rgba(52,58,67,0.08),0px 5px 15px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 5px 10px rgba(52,58,67,0.08),0px 15px 35px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 10px 20px rgba(52,58,67,0.08),0px 20px 40px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
    '0px 0px 2px rgba(52,58,67,0.10),0px 18px 38px rgba(52,58,67,0.08),0px 35px 65px rgba(52,58,67,0.08)',
  ],
  typography: {
    fontFamily: [
      '"Inter"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontFamily: 'DM Sans',
      fontWeight: 500,
      fontSize: '40px',
      lineHeight: '1.2',
    },
    h2: {
      fontFamily: 'DM Sans',
      fontWeight: 500,
      fontSize: '32px',
      lineHeight: '1.2',
    },
    h3: {
      fontFamily: 'DM Sans',
      fontWeight: 500,
      fontSize: '28px',
      lineHeight: '1.2',
    },
    h4: {
      fontFamily: 'DM Sans',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '1.2',
    },
    h5: {
      fontFamily: 'DM Sans',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '1.2',
    },
    h6: {
      fontWeight: '400',
      fontSize: '16px',
      color: '#53627C',
      lineHeight: '1.2',
    },
    button: {
      fontFamily: 'DM Sans',
      textTransform: 'none',
      fontSize: '16px',
      fontWeight: '500',
    },
  },
  shape: {
    borderRadius: 8
  },
})

export default theme;
