
// ReactJS
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useFieldArray, useForm } from "react-hook-form";

// External dependencies
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';

// External components
//import CurrencyField from '../../components/CurrencyField/CurrencyField.js';

// MUI Components
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, FormLabel, Grid, Link, MenuItem, OutlinedInput, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from "@material-ui/core/styles";

// Constants
import { routes, formatNumber } from "../../constants.js/misc.js";
import { statusOrders, statusShipping } from "../../constants.js/orders.js";
import { countries, colombianCities } from '../../constants.js/countries.js';

// Assets
import clientIcon from "../../assets/img/clientIcon.png";
import deleteIcon from "../../assets/icons/deleteIcon.svg";

// Services
import { createOrder, getOrderFilters } from '../../services/orders.js';
import { getProducts, getProductsOrder } from '../../services/products.js';
import { DivContainerTable } from '../../constants.js/containers.js';
import styled from '@emotion/styled';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        marginBottom: 10,
    },
    card: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        padding: 26,
        marginBottom: 30
    },
    title: {
        fontSize: '22px',

    },
    header: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '1rem',
        width: '100%',
    },
    button: {
        marginTop: '1rem',
        borderRadius: 8,
        width: '100%',
    },
    numberFieldIcon: {
        width: '10%',
        color: '#48AC98',
        lineHeight: 0,
    },
    numberFieldCode: {
        width: '15%',
    },
    numberFieldWhatsapp: {
        width: '85%',
        textAlign: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        margin: 0,
    },
    total: {
        padding: '16px',
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end'
    },
});

const Helper = styled(FormControl)`
    &:focus-within {
        p {
            visibility: visible;
            color: #53627C;
            transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        };
        &:hover {
            p {
                font-size: 1rem;
                transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
            };
        };
        
    }
`;


const fieldsSchema = yup.object({
    delivery: yup.string().required("Se requiere el estado del pedido"),

    date: yup.string().required("Se requiere la fecha"),
    stores: yup.string().required("Se requiere una linea/sucursal"),
    addProducts: yup.string(),
    products: yup.array(),
    shippingCost: yup.number(),

    name: yup.string().required("Se requiere el nombre"),
    code: yup.string(),
    phone: yup.string().required("Se requiere el teléfono"),
    email: yup.string().required("Se requiere el correo electrónico"),
    typeDocument: yup.string(),
    document: yup.string().required("Se requiere el documento"),

    country: yup.string().required("Se requiere el país"),
    city: yup.string().required("Se requiere la ciudad"),
    address: yup.string().required("Se requiere la dirección"),
    detailAddress: yup.string(),
    comments: yup.string(),

    shipping: yup.string().required("Se requiere el estado del envio"),
});

const CreateOrder = ({ storeId }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });

    const [open, setOpen] = useState({ firstOption: false, secondOption: false });
    const [openLine, setOpenLine] = useState(false);
    const [openProduct, setOpenProduct] = useState(false);
    const [modalClient, setModalClient] = useState(false);
    const [modalShipping, setModalShipping] = useState(false);

    const [value, setValue] = useState('');
    const [otherValue, setOtherValue] = useState('');
    const [valueDelivery, setValueDelivery] = useState('');
    const [valueStores, setValueStores] = useState('');
    const [valueShipping, setValueShipping] = useState('');
    const [shipping, setShipping] = useState([]);
    const [delivery, setDelivery] = useState([]);
    const [stores, setStores] = useState([]);

    const [subTotal, setSubTotal] = useState(0);

    const [productOptions, setProductOptions] = useState([]);
    const [shopProducts, setShopProducts] = useState([]);

    const [code, setCode] = useState('57');

    const { register, control, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            delivery: "",

            date: "",
            stores: "",
            addProducts: "",
            products: [],
            shippingCost: 0,

            name: sessionStorage.getItem('infoClient') ? JSON.parse(sessionStorage.getItem('infoClient'))?.name : "",
            code: sessionStorage.getItem('infoClient') ? JSON.parse(sessionStorage.getItem('infoClient'))?.code : "57",
            phone: sessionStorage.getItem('infoClient') ? JSON.parse(sessionStorage.getItem('infoClient'))?.phone : "",
            email: sessionStorage.getItem('infoClient') ? JSON.parse(sessionStorage.getItem('infoClient'))?.email : "",
            typeDocument: sessionStorage.getItem('infoClient') ? JSON.parse(sessionStorage.getItem('infoClient'))?.typeDocument : "CC",
            document: sessionStorage.getItem('infoClient') ? JSON.parse(sessionStorage.getItem('infoClient'))?.document : "",

            country: sessionStorage.getItem('infoShipping') ? JSON.parse(sessionStorage.getItem('infoShipping'))?.country : "",
            city: sessionStorage.getItem('infoShipping') ? JSON.parse(sessionStorage.getItem('infoShipping'))?.city : "",
            address: sessionStorage.getItem('infoShipping') ? JSON.parse(sessionStorage.getItem('infoShipping'))?.address : "",
            detailAddress: sessionStorage.getItem('infoShipping') ? JSON.parse(sessionStorage.getItem('infoShipping'))?.detailAddress : "",
            comments: sessionStorage.getItem('infoShipping') ? JSON.parse(sessionStorage.getItem('infoShipping'))?.comments : "",

            shipping: "",
        },
        resolver: yupResolver(fieldsSchema)
    });

    const { fields, append, remove } = useFieldArray({
        control: control,
        name: 'products',
    });

    const handleAppend = useCallback((data) => {
        data.length === 1
            && append({
                id: data[0]?.id,
                name: data[0]?.name,
                variants: data[0]?.product_options,
                sku: data[0]?.sku,
                quantity: 1,
                price: data[0]?.price,
                total: data[0]?.price,
            });

    }, [append])

    const handleRemove = useCallback(({ index }) => {
        remove(index);
    }, [remove])

    const handleNotificacionClient = (message, variant) => {
        enqueueSnackbar(message, { variant });

        sessionStorage.setItem('changeClient', true);
        sessionStorage.setItem('infoClient', JSON.stringify({
            name: watch('name'),
            document: watch('document'),
            phone: watch('phone'),
            email: watch('email'),
            typeDocument: watch('typeDocument'),
            code: watch('code'),
        }));
        setModalClient(false);
    }

    const handleNotificacionShipping = (message, variant) => {
        enqueueSnackbar(message, { variant });

        sessionStorage.setItem('changeShipping', true);
        sessionStorage.setItem('infoShipping', JSON.stringify({
            country: watch('country'),
            city: watch('city'),
            address: watch('address'),
            detailAddress: watch('detailAddress'),
            comments: watch('comments'),
        }));
        setModalShipping(false);
    }

    const handleCodeChange = newValue => {
        setCode(newValue);
    }

    const countryToFlag = (isoCode) => {
        return typeof String.fromCodePoint !== 'undefined' ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397)) : isoCode
    }

    const handleOrderStatus = (status) => {
        const size = Object.keys(statusOrders).length

        for (let i = 1; i <= size; i++) {
            if (statusOrders[i] === status) {
                return i
            }
        }
    }

    const handleShippingStatus = (status) => {
        let id = 0;
        const size = Object.keys(statusShipping).length + 5

        for (let i = 6; i <= size; i++) {
            if (statusShipping[i] === status) {
                id = i
            }
        }

        return id;
    }

    const handleSubtotal = () => {
        let subtotal = 0;
        for (let i = 0; i < fields.length; i++) {
            subtotal += fields[i]?.price * Number(watch(`products.${i}.quantity`));
        }
        setSubTotal(subtotal)
    }

    const handleVariantId = (idProduct, arrayVariants) => {
        let id = 0;

        shopProducts.filter(product => product.id === idProduct).map(product => {
            product.product_variants !== null
                ? product.product_variants.map(option => {
                    if (JSON.stringify(arrayVariants) === JSON.stringify(option?.options)) {
                        id = option?.id
                    }
                })
                : id = 0;
        })
        return id;
    }

    const onSubmit = data => {
        var value = {
            comments: data?.comments,
            customer: {
                first_name: data?.name.split(' ')[0],
                last_name: data?.name.split(' ')[1],
                phone_number: `${data?.code}${data?.phone}`,
                document_type: data?.typeDocument,
                dni: data?.document,
                email: data?.email
            },
            date: `${data?.date?.split('T')[0]} ${data?.date?.split('T')[1]}:00`,
            order_status_id: handleOrderStatus(data?.delivery),
            products: data?.products.map(product => ({
                product_id: product?.id,
                product_variant_id: handleVariantId(product?.id, product?.optionVariants),
                quantity: Number(product?.quantity),
                price: product?.price * Number(product?.quantity),
            })),
            shipping_cost: Number(data?.shippingCost),
            shipping_information: {
                country: "CO",
                city: data?.city,
                address: data?.address,
                address_detail: data?.detailAddress,
                comments: data?.comments
            },
            shipping_order_status_id: handleShippingStatus(data?.shipping),
            user_store_id: stores.find(store => store.mobile_phone_number === data.stores).id,
        }

        createOrder(storeId, value)
            .then(res => {
                enqueueSnackbar("Se a creado exitosamente el pedido", { variant: 'success' });
                sessionStorage.removeItem('changeShipping');
                sessionStorage.removeItem('infoShipping');
                sessionStorage.removeItem('changeClient');
                sessionStorage.removeItem('infoClient');
                history.push('/orders');
            })
            .catch(err => {
                enqueueSnackbar("Hubo un error al crear el pedido, revisa la información de tus productos e intentelo nuevamente", { variant: 'error' });
            })
    }

    useEffect(() => {
        getOrderFilters(storeId)
            .then(res => {
                setShipping(res?.data?.order_shipping_counter)
                setDelivery(res?.data?.order_status_counter)
                setStores(res?.data?.user_stores)
            })
            .catch(err => {
                console.log(err)
            })
    }, [storeId])

    useEffect(() => {
        getProducts(storeId, value?.name)
            .then(res => {
                handleAppend(res?.data)
                setProductOptions(res?.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [storeId, value, handleAppend])

    useEffect(() => {
        otherValue?.length >= 3 && getProducts(storeId, otherValue)
            .then(res => {
                setShopProducts(res?.data)
                setProductOptions(res?.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [storeId, otherValue, handleAppend])

    useEffect(() => {
        handleSubtotal();
    }, [fields]);

    console.log(value);

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={isTabletOrMobile ? 0 : 3}>
                    {isTabletOrMobile &&
                        <Grid item xs={12} style={{ padding: '0px 12px' }}>
                            <Link
                                component={RouterLink}
                                to={routes.ordersPage}
                            >
                                {t('OrderTitle')}
                            </Link>
                        </Grid>
                    }

                    <Grid item xs={12} className={classes.container}>
                        <Grid item xs={8}>
                            <Typography
                                variant={isTabletOrMobile ? 'h5' : 'h4'}
                                style={{ marginBottom: '10px' }}
                            >
                                {t('Order.create')}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                type="submit"
                                className="btnLink"
                                color="primary"
                                variant="contained"
                            >
                                {t('SaveChangesBtn')}
                            </Button>
                        </Grid>
                    </Grid>

                    {!isTabletOrMobile &&
                        <Grid item xs={12} style={{ padding: '0px 12px' }}>
                            <Link
                                component={RouterLink}
                                to={routes.ordersPage}
                            >
                                {t('OrderTitle')}
                            </Link>
                        </Grid>
                    }

                    <Grid item xs={12} sm={12} md={12} lg={8}>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Card elevation={1} className={classes.card}>
                                    <Typography className={classes.title}>
                                        Estado del pedido
                                    </Typography>
                                    <Autocomplete
                                        defaultValue={valueDelivery}
                                        onChange={(e, value) => setValueDelivery(value)}
                                        style={{ width: '178px' }}
                                        open={open?.firstOption}
                                        onOpen={() => {
                                            setOpen({ firstOption: true, secondOption: false })
                                        }}
                                        onClose={() => {
                                            setOpen({ firstOption: false, secondOption: false })
                                        }}
                                        getOptionSelected={(option, value) => option.status_name === value.name}
                                        getOptionLabel={(option) => option.status_name}
                                        options={delivery}
                                        renderInput={(params) => (
                                            <FormControl>
                                                <TextField {...params} {...register('delivery')} variant="outlined" />
                                            </FormControl>
                                        )}
                                    />
                                    {errors?.delivery && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.delivery?.message}</Typography>}
                                </Card>
                                <Card elevation={1} className={classes.card}>
                                    <Typography className={classes.title}>
                                        Detalles de la orden
                                    </Typography>
                                    <Typography style={{ marginTop: 18 }}>
                                        Fecha
                                    </Typography>
                                    <TextField {...register("date")} variant="outlined" type='datetime-local' style={{ width: '178px', paddingTop: 9 }} />
                                    {errors?.date && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.date?.message}</Typography>}

                                    <Typography style={{ marginTop: 18 }}>
                                        Línea/Sucursal
                                    </Typography>
                                    <Autocomplete
                                        defaultValue={valueStores}
                                        onChange={(e, value) => setValueStores(value)}
                                        style={{ width: '178px' }}
                                        open={openLine}
                                        onOpen={() => {
                                            setOpenLine(true);
                                        }}
                                        onClose={() => {
                                            setOpenLine(false);
                                        }}
                                        getOptionSelected={(option, value) => option.mobile_phone_number === value.name}
                                        getOptionLabel={(option) => option.mobile_phone_number}
                                        options={stores}
                                        renderInput={(params) => (
                                            <FormControl>
                                                <TextField {...params} {...register('stores')} variant="outlined" />
                                            </FormControl>
                                        )}
                                    />
                                    {errors?.stores && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.stores?.message}</Typography>}
                                    <Typography style={{ marginTop: 18 }}>
                                        {t('OrdersTablePage.searchProduct')}
                                    </Typography>
                                    <Autocomplete
                                        defaultValue={value}
                                        onChange={(e, value) => setValue(value)}
                                        style={{ width: '100%' }}
                                        open={openProduct}
                                        onOpen={() => {
                                            setOpenProduct(true);
                                        }}
                                        onClose={() => {
                                            setOpenProduct(false);
                                        }}
                                        getOptionSelected={(option, value) => option.name === value.name}
                                        getOptionLabel={(option) => option.name}
                                        options={productOptions}
                                        renderInput={(params) => (
                                            <Helper>
                                                <FormHelperText>
                                                    **{t('OrdersTablePage.searchProduct.anotation')}
                                                </FormHelperText>
                                                <TextField
                                                    {...params}
                                                    {...register('addProducts')}
                                                    onChange={(e) => setOtherValue(e?.target?.value)}
                                                    variant="outlined"
                                                    placeholder="Buscar producto" />
                                            </Helper>
                                        )}
                                    />
                                    <DivContainerTable>
                                        <Table style={{ backgroundColor: '#F8F9FB', marginTop: 32 }} stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{t('OrdersTablePage.products.headers.product')}</TableCell>
                                                    <TableCell>{t('OrdersTablePage.headers.variants')}</TableCell>
                                                    <TableCell>{t('OrdersTablePage.products.headers.sku')}</TableCell>
                                                    <TableCell>{t('OrdersTablePage.products.headers.amount')}</TableCell>
                                                    <TableCell>{t('OrdersTablePage.products.headers.price')}</TableCell>
                                                    <TableCell>{t('OrdersTablePage.products.headers.total')}</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {fields.map((product, index) => (
                                                    <TableRow>
                                                        <TableCell className={classes.centerTable}>
                                                            <Link
                                                                component={RouterLink}
                                                                to={routes.updateProduct + '/' + product?.id}
                                                                color="primary"
                                                            >
                                                                {product?.name}
                                                            </Link>
                                                        </TableCell>

                                                        <TableCell>
                                                            {product?.variants?.length === 0
                                                                ? <>{t('OrdersTablePage.products.noVariants')}</>
                                                                : <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                    {product?.variants?.map((variant, newIndex) => (
                                                                        <Select
                                                                            {...register(`products.${index}.optionVariants.${newIndex}`)}
                                                                            id='variants'
                                                                            input={<OutlinedInput fullWidth />}
                                                                        >
                                                                            {variant?.options?.map((option, index) => (
                                                                                <MenuItem key={index} value={option?.name}>
                                                                                    {option?.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>

                                                                    ))}
                                                                </div>
                                                            }
                                                        </TableCell>

                                                        <TableCell>
                                                            {product?.sku}
                                                        </TableCell>

                                                        <TableCell>
                                                            <TextField
                                                                {...register(`products.${index}.quantity`)}
                                                                variant="outlined"
                                                                onClick={handleSubtotal}
                                                                defaultValue={1}
                                                                type='number'
                                                                InputProps={{ inputProps: { min: 1 } }}
                                                                style={{ width: 70 }}
                                                            />
                                                        </TableCell>

                                                        <TableCell>
                                                            {formatNumber(product?.price)}
                                                        </TableCell>

                                                        <TableCell>
                                                            {formatNumber(product?.price * watch(`products.${index}.quantity`))}
                                                        </TableCell>

                                                        <TableCell>
                                                            <img src={deleteIcon} alt="delete" onClick={() => handleRemove({ index: index })} style={{ cursor: 'pointer' }} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </DivContainerTable>
                                    <div className={classes.total}>
                                        <div style={{ marginRight: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                            <Typography gutterBottom variant="body1">Subtotal:</Typography>
                                            <Typography gutterBottom variant="body1">Costos de envio:</Typography>
                                            <Typography gutterBottom variant="body1">Total:</Typography>
                                        </div>
                                        <div>
                                            <Typography gutterBottom variant="body1" style={{ textAlign: 'right' }}>{formatNumber(subTotal)}</Typography>
                                            <Typography gutterBottom variant="body1">
                                                <TextField
                                                    {...register('shippingCost')}
                                                    style={{ width: '120px', textAlignLast: 'right' }}
                                                    defaultValue={0}
                                                    name="shippingCost"
                                                    variant="outlined"
                                                />
                                            </Typography>
                                            <Typography gutterBottom variant="body1" style={{ textAlign: 'right' }}>
                                                {formatNumber(subTotal + Number(watch('shippingCost')))}
                                            </Typography>
                                        </div>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Grid item xs={12}>
                            <Card elevation={1} className={classes.card} style={{ justifyContent: 'flex-start' }}>
                                <Grid item xs={12} style={{ display: 'flex', marginBottom: 15 }}>
                                    <img src={clientIcon} alt='clientIcon' style={{ marginRight: 10 }} />
                                    <Typography className={classes.title}>
                                        Cliente
                                    </Typography>
                                </Grid>
                                {sessionStorage.getItem("changeClient") &&
                                    <React.Fragment>
                                        <Grid item xs={12} style={{ display: 'flex', }}>
                                            <Typography>
                                                {JSON.parse(sessionStorage.getItem('infoClient'))?.email}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: 'flex', }}>
                                            <Typography>
                                                Telefono: {`+${JSON.parse(sessionStorage.getItem('infoClient'))?.code}`} {JSON.parse(sessionStorage.getItem('infoClient'))?.phone}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: 'flex', }}>
                                            <Typography>
                                                Identificacion: {JSON.parse(sessionStorage.getItem('infoClient'))?.typeDocument} {JSON.parse(sessionStorage.getItem('infoClient'))?.document}
                                            </Typography>
                                        </Grid>
                                    </React.Fragment>
                                }
                                {errors?.name && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.name?.message}</Typography>}
                                {errors?.phone && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.phone?.message}</Typography>}
                                {errors?.email && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.email?.message}</Typography>}
                                {errors?.document && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.document?.message}</Typography>}
                                <Button
                                    onClick={() => setModalClient(!modalClient)}
                                    className={classes.button}
                                    color="primary"
                                    variant="contained"
                                >
                                    {`${sessionStorage.getItem("changeClient") ? 'Editar' : 'Agregar'} cliente`}

                                </Button>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card elevation={1} className={classes.card} style={{ justifyContent: 'flex-start' }}>
                                <Typography className={classes.title} style={{ marginBottom: 15 }}>
                                    Datos de envío
                                </Typography>
                                {sessionStorage.getItem("changeShipping") &&
                                    <React.Fragment>
                                        <Grid item xs={12} style={{ display: 'flex', }}>
                                            <Typography>
                                                Telefono: {`+${JSON.parse(sessionStorage.getItem('infoClient'))?.code}`} {JSON.parse(sessionStorage.getItem('infoClient'))?.phone}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: 'flex', }}>
                                            <Typography>
                                                Nombre: {JSON.parse(sessionStorage.getItem('infoClient'))?.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: 'flex', }}>
                                            <Typography>
                                                Dirección: {JSON.parse(sessionStorage.getItem('infoShipping'))?.address}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: 'flex', }}>
                                            <Typography>
                                                Detalles de dirección: {JSON.parse(sessionStorage.getItem('infoShipping'))?.detailAddress}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: 'flex', }}>
                                            <Typography>
                                                Ciudad: {JSON.parse(sessionStorage.getItem('infoShipping'))?.city}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: 'flex', }}>
                                            <Typography>
                                                Pais: {JSON.parse(sessionStorage.getItem('infoShipping'))?.country}
                                            </Typography>
                                        </Grid>
                                    </React.Fragment>
                                }
                                    {errors?.country && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.country?.message}</Typography>}
                                    {errors?.city && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.city?.message}</Typography>}
                                    {errors?.address && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.address?.message}</Typography>}
                                <Button
                                    onClick={() => setModalShipping(!modalShipping)}
                                    className={classes.button}
                                    color="primary"
                                    variant="contained"
                                >
                                    {`${sessionStorage.getItem("changeShipping") ? 'Editar' : 'Agregar'} detalles de envío`}
                                </Button>
                                <Typography className={classes.title} style={{ marginTop: 30 }}>
                                    Estado del envío
                                </Typography>
                                <Autocomplete
                                    defaultValue={valueShipping}
                                    onChange={(e, value) => setValueShipping(value)}
                                    style={{ width: '178px' }}
                                    open={open?.secondOption}
                                    onOpen={() => {
                                        setOpen({ firstOption: false, secondOption: true });
                                    }}
                                    onClose={() => {
                                        setOpen({ firstOption: false, secondOption: false })
                                    }}
                                    getOptionSelected={(option, value) => option.status_name === value.name}
                                    getOptionLabel={(option) => option.status_name}
                                    options={shipping}
                                    renderInput={(params) => (
                                        <FormControl>
                                            <TextField {...params} {...register('shipping')} variant="outlined" />
                                        </FormControl>
                                    )}
                                />
                                {errors?.shipping && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.shipping?.message}</Typography>}
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card elevation={1} className={classes.card} style={{ justifyContent: 'flex-start' }}>
                                <Typography className={classes.title}>
                                    Comentarios del pedido
                                </Typography>
                                {JSON.parse(sessionStorage.getItem("infoShipping"))?.comments === undefined || JSON.parse(sessionStorage.getItem("infoShipping"))?.comments === ""
                                    ?
                                    <Typography style={{ marginTop: 18, color: '#979797' }}>
                                        Este pedido no tiene comentarios
                                    </Typography>
                                    :
                                    <Typography style={{ marginTop: 18, color: '#0F2930', backgroundColor: '#FCF3EA', borderRadius: 8, padding: 15, width: '100%' }}>
                                        {JSON.parse(sessionStorage.getItem("infoShipping"))?.comments}
                                    </Typography>
                                }
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </form>

            <Dialog open={modalClient} onClose={() => setModalClient(!modalClient)}>
                <DialogTitle>
                    <Typography className={classes.title}>
                        Datos del cliente
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Card elevation={0} className={classes.cardList} style={{ borderRadius: 0, flexDirection: 'column', alignItems: 'flex-start' }}>
                        <FormControl
                            variant="outlined"
                            margin="normal"
                            fullWidth>
                            <FormLabel htmlFor='name' className={classes.labels}>Nombre y Apellido</FormLabel>
                            <OutlinedInput
                                {...register('name')}
                                defaultValue={JSON.parse(sessionStorage.getItem('infoClient'))?.name}
                                type="text"
                                id='name'
                                fullWidth
                            />
                        </FormControl>
                        {errors?.name && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.name?.message}</Typography>}

                        <FormControl
                            variant="outlined"
                            margin="normal"
                            fullWidth>
                            <FormLabel htmlFor='customerWhatsapp' className={classes.labels}>
                                Número de WhatsApp
                            </FormLabel>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'flex-end' }}>
                                {/*
                                <div className={classes.numberFieldIcon}>
                                    <Whatsapp fontSize="small" />
                                </div>
                                */}
                                <div className={classes.numberFieldCode}>
                                    <Autocomplete
                                        style={{ width: "100%" }}
                                        options={countries}
                                        noOptionsText="No hay resultados"
                                        value={code || ''}
                                        onChange={(event, value) => handleCodeChange(value.phone)}
                                        getOptionLabel={option => option.phone || code}
                                        renderOption={option => (
                                            <Typography>
                                                <span>{countryToFlag(option.code)}</span>{`${option.label} +${option.phone}`}
                                            </Typography>
                                        )}
                                        renderInput={params => (
                                            <FormControl>
                                                <TextField
                                                    {...params}
                                                    {...register('code')}
                                                    defaultValue={JSON.parse(sessionStorage.getItem('infoClient'))?.code}
                                                    variant="outlined"
                                                    placeholder="(+00)"
                                                    fullWidth
                                                />
                                            </FormControl>
                                        )} />
                                </div>
                                <FormControl className={classes.numberFieldWhatsapp}>
                                    <TextField
                                        {...register("phone")}
                                        defaultValue={JSON.parse(sessionStorage.getItem('infoClient'))?.phone}
                                        variant="outlined"
                                        placeholder="Ingresa tu número de WhatsApp"
                                        type="number"
                                        fullWidth
                                    />
                                </FormControl>
                            </div>
                        </FormControl>
                        {errors?.phone && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.phone?.message}</Typography>}

                        <FormControl
                            variant="outlined"
                            margin="normal"
                            fullWidth>
                            <FormLabel htmlFor='email' className={classes.labels}>
                                Correo electrónico
                            </FormLabel>
                            <OutlinedInput
                                {...register('email')}
                                type="email"
                                defaultValue={JSON.parse(sessionStorage.getItem('infoClient'))?.email}
                                id='email'
                                fullWidth
                            />
                        </FormControl>
                        {errors?.email && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.email?.message}</Typography>}

                        <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Grid item xs={2}>
                                <FormControl
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth>
                                    <FormLabel htmlFor='typeDocument' className={classes.labels}>
                                        Documento
                                    </FormLabel>
                                    <Select
                                        {...register('typeDocument')}
                                        defaultValue={JSON.parse(sessionStorage.getItem('infoClient'))?.typeDocument}
                                        id='typeDocument'
                                    >
                                        <MenuItem value="CC">
                                            CC
                                        </MenuItem>
                                        <MenuItem value="TI">
                                            TI
                                        </MenuItem>
                                        <MenuItem value="Pasaporte">
                                            Pasaporte
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                {errors?.typeDocument && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.typeDocument?.message}</Typography>}
                            </Grid>
                            <Grid item xs={9}>
                                <FormControl
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth>
                                    <FormLabel htmlFor='document' className={classes.labels}>
                                        Número de identificación
                                    </FormLabel>
                                    <OutlinedInput
                                        {...register('document')}
                                        defaultValue={JSON.parse(sessionStorage.getItem('infoClient'))?.document}
                                        id='document'
                                        fullWidth
                                    />
                                </FormControl>
                                {errors?.document && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.document?.message}</Typography>}
                            </Grid>
                        </Grid>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setModalClient(false)}
                        variant="contained"
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => handleNotificacionClient("Datos del cliente guardados exitosamente", "success")}
                        autoFocus
                        color="primary"
                        variant="contained"
                    >
                        Guardar cambios
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={modalShipping} onClose={() => setModalShipping(!modalShipping)}>
                <DialogTitle>
                    <Typography className={classes.title}>
                        Datos de envío
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Card elevation={0} className={classes.cardList} style={{ borderRadius: 0, flexDirection: 'column', alignItems: 'flex-start' }}>
                        <FormControl
                            variant="outlined"
                            margin="normal"
                            fullWidth
                        >
                            <FormLabel htmlFor='country' className={classes.labels}>Pais</FormLabel>
                            <Select
                                {...register('country')}
                                defaultValue={JSON.parse(sessionStorage.getItem('infoShipping'))?.country}
                                id='country'
                                input={
                                    <OutlinedInput
                                        {...register('country')}
                                        defaultValue={JSON.parse(sessionStorage.getItem('infoShipping'))?.country}
                                        type="text"
                                        id='country'
                                        fullWidth
                                    />
                                }
                            >
                                {countries.map(country => (
                                    <MenuItem key={country?.code} value={country?.label}>
                                        {country.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {errors?.country && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.country?.message}</Typography>}

                        <FormControl
                            variant="outlined"
                            margin="normal"
                            fullWidth
                        >
                            <FormLabel htmlFor='city' className={classes.labels}>Ciudad</FormLabel>
                            <Select
                                {...register('city')}
                                defaultValue={JSON.parse(sessionStorage.getItem('infoShipping'))?.city}
                                id='city'
                                input={
                                    <OutlinedInput
                                        {...register('city')}
                                        defaultValue={JSON.parse(sessionStorage.getItem('infoShipping'))?.city}
                                        type="text"
                                        id='city'
                                        fullWidth
                                    />
                                }
                            >
                                {colombianCities.map((option, index) => (
                                    <MenuItem key={index} value={option?.city}>
                                        {option.city}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {errors?.city && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.city?.message}</Typography>}

                        <FormControl
                            variant="outlined"
                            margin="normal"
                            fullWidth>
                            <FormLabel htmlFor='address' className={classes.labels}>Dirección</FormLabel>
                            <OutlinedInput
                                {...register('address')}
                                defaultValue={JSON.parse(sessionStorage.getItem('infoShipping'))?.address}
                                type="text"
                                id='address'
                                fullWidth
                            />
                        </FormControl>
                        {errors?.address && <Typography variant="body1" style={{ color: '#F80000', fontSize: 12 }}>{errors?.address?.message}</Typography>}

                        <FormControl
                            variant="outlined"
                            margin="normal"
                            fullWidth>
                            <FormLabel htmlFor='detailAddress' className={classes.labels}>Detalles de la dirección</FormLabel>
                            <OutlinedInput
                                {...register('detailAddress')}
                                defaultValue={JSON.parse(sessionStorage.getItem('infoShipping'))?.detailAddress}
                                type="text"
                                id='detailAddress'
                                fullWidth
                            />
                        </FormControl>

                        <FormControl
                            variant="outlined"
                            margin="normal"
                            fullWidth>
                            <FormLabel htmlFor='comments' className={classes.labels}>Comentarios</FormLabel>
                            <OutlinedInput
                                {...register('comments')}
                                defaultValue={JSON.parse(sessionStorage.getItem('infoShipping'))?.comments}
                                type="text"
                                id='comments'
                                fullWidth
                                multiline
                                rows={3}
                            />
                        </FormControl>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setModalShipping(false)}
                        variant="contained"
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => handleNotificacionShipping("Datos de envio guardados exitosamente", "success")}
                        autoFocus
                        color="primary"
                        variant="contained"
                    >
                        Guardar cambios
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

const mapStateToProps = ({ auth }) => ({
    storeId: auth.storeId
});

export default connect(
    mapStateToProps
)(CreateOrder);