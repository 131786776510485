import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../constants.js/colors';
// styles
const useStyles = makeStyles({
    div1: {
        height: '28px',
        display: 'inline-table',
        padding: '4px 10px',
        //background: '#F8F9FB',
        border: `2px solid ${colors.primary.dark}`,
        borderWidth: '2px',
        borderRadius: '32px',
        textAlign: 'center',
        marginLeft: '9px',
        "& p": {
            color: `${colors.primary.dark}`,
        }
    },
    div2: {
        height: '28px',
        display: 'inline-table',
        padding: '4px 10px',
        //background: '#F8F9FB',
        border: '2px solid #8181A5',
        borderWidth: '2px',
        borderRadius: '32px',
        textAlign: 'center',
        marginLeft: '9px',
        "& p": {
            color: '#8181A5',
        }
    },
    state: {
        fontWeight: 'bold',
        lineHeight: '15px',
    }
});

const StatePill = ({ state, type }) => {
    const classes = useStyles();

    return (
        <div className={type === 1 ? classes.div1 : classes.div2}>
            <Typography
                variant="body1"
                className={classes.state}
                style={{ textTransform: 'capitalize' }}
            >
                {state}
            </Typography>
        </div>
    );
};

export default StatePill;
//export default connect()(StatePill);