import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Controller, useForm } from 'react-hook-form';

// External dependencies
import { useSnackbar } from 'notistack';

// UI material dependencies
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Input,
    InputAdornment,
    LinearProgress,
    Link,
    OutlinedInput,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip'

import { makeStyles, withStyles } from '@material-ui/core/styles';

// Internal components
import ViewDataEmpty from '../../components/ViewDataEmpty/ViewDataEmpty';

// Internal dependencies
import { errorToast, successToast } from '../../constants.js/misc';
import { setClientToEdit, setPageState, setRowsPerPageState } from '../../modules/clients';
import ImageDefaultInventory from '../../assets/img/default/EmptyInventory.png';
import CurrencyField from '../../components/CurrencyField/CurrencyField';

// Assets
import SearchIcon from '../../assets/icons/SearchIcon.svg';
import ExportIcon from '../../assets/icons/ExportIcon.svg';
import ImportIcon from '../../assets/icons/ImportIcon.svg';
import CloseIcon from '../../assets/icons/CloseIcon.svg';
import CheckIcon from '../../assets/icons/CheckIcon.svg';

// Search
import { ProductImage } from '../ProductsTablePage/ProductsTablePage.styles';

// Services
import { addProductInventory, addProductInventoryWithVariant, downloadStock, getInventory, importInventoryDownload } from '../../services/inventory';
import { IconInfo } from '../../assets/icons/FuncInfoIcon'
import { colors } from '../../constants.js/colors';

const TextOnlyTooltip = withStyles({
    tooltip: {
        fontSize: 15
    }
})(Tooltip)

const useStyles = makeStyles({
    root: { width: '100%' },
    container: {
        maxHeight: '570px',
    },
    newContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    containerDesktop: {
        maxHeight: '560px',
    },
    gridContainer: {
        "& div": {
            maxWidth: 'inherit',
            padding: '8px'
        },
    },
    btn: {
        "& .MuiButton-label": {
            fontSize: 14
        },
        "& .MuiButton-contained": {
            background: '#fff'
        }
    },
    btnFilter: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'center',
        margin: '8px 8px 0px 8px',

        "&:disabled": {
            backgroundColor: '#F6F6F6',
            color: '#8181A5',
        },
    },
    filterInput: {
        backgroundColor: '#fff',
        borderRadius: 8,
        boxShadow: '0px 0px 2px rgba(52, 58, 67, 0.1), 0px 1px 2px rgba(52, 58, 67, 0.08), 0px 1px 4px rgba(52, 58, 67, 0.08)',
        color: '#53627C',
        height: 42,
        padding: '14px 16px',
        width: '100%',
    },
    spacer: {
        "& .MuiTablePagination-spacer": {
            flex: 0
        }
    },
    notification: {
        backgroundColor: '#C1E9EB',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: 16,
        borderRadius: 8,
        margin: '0px 0px 28px 0px',
    }
});

/**
 * Renders the orders table
 * @param {number} storeId store id
 * @param {number} initialPage initial page to show
 * @param {number} initialRowsPerPage initial rows per page to show
 * @param {function} setPageState sets the page to show on the state
 * @param {function} setRowsPerPageState sets the rows per page the state
 */
export const InventoryPage = ({
    storeId,
    initialPage,
    initialRowsPerPage,
    setPageState,
    setRowsPerPageState,
    storeData,
    userEmail,
}) => {
    // CUSTOM HOOKS
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();

    // USESTATES
    const [gettingData, setGettingData] = useState(true);
    const [page, setPage] = useState(initialPage);
    const [rows, setRows] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
    const [totalRows, setTotalRows] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [apply, isApply] = useState(false);

    const [filter, setFilter] = useState('');

    const [rowSelected, setRowSelected] = useState(null);

    // const [isExport, setExport] = useState(false);

    const columns = [
        { id: 'image', label: '', mobile: true },
        { id: 'name', label: t('InventoryPage.filter.name'), mobile: true },
        { id: 'sku', label: t('InventoryPage.filter.sku'), mobile: true },
        { id: 'normalPrice', label: t('InventoryPage.filter.normalPrice'), mobile: true },
        { id: 'discountPrice', label: t('InventoryPage.filter.discountPrice'), mobile: true },
        { id: 'available', label: t('InventoryPage.filter.available'), mobile: true },
        { id: 'edit', label: t('InventoryPage.filter.edit'), mobile: true },
        { id: 'actions', label: '', mobile: true },
    ];

    const {
        register,
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors }
    } = useForm({
        mode: 'all',
    });

    // FUNCTIONS
    const handleImageBase64 = (thumbnail) => {
        let image_src = JSON.stringify({
            "bucket": "wa10x.v2",
            "key": `${thumbnail}`,
            "edits": {
                "resize": {
                    "width": 600,
                    "height": 600,
                    "fit": "cover"
                },
                "rotate": null,
            }
        })

        return image_src;
    }

    const handleExport = () => {
        // setExport(true)
        downloadStock(storeId)
    }



    // HANDLECHANGE
    const handleChangePage = (e, newPage) => {
        setPage(newPage);
        setPageState(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setPageState(0);
        setRowsPerPage(+event.target.value);
        setRowsPerPageState(+event.target.value);
    };

    const handleFilterChange = ({ target }) => {
        setFilter(target.value);
        setPage(0);
    };

    // CAPITALIZE
    const capitalize = (entry) => {
        return entry.charAt(0).toUpperCase() + entry.slice(1);
    }

    // USEEFFECTS
    useEffect(() => {
        setGettingData(true);
        isApply(false)
        getInventory(
            storeId, // -> storeId
            rowsPerPage, // -> limit
            page,  // -> page
            filter.replace(/\+/g, ' ').trim(),  // -> search
        )
            .then(res => {
                setTotalRows(+res.headers['x-total-count'] || 12);
                res.data === ''
                    ? setRows([])
                    : setRows(res.data.map(item => ({
                        ...item,
                        showProducts: false
                    })));
                setGettingData(false);
                setFirstLoad(false);
            })
            .catch(err => {
                console.log('Error ClientPage', err);
                setGettingData(false);
                setFirstLoad(false);
                enqueueSnackbar(t('ClientPage.tableError'), errorToast)
            });
    }, [storeId, rowsPerPage, page, enqueueSnackbar, t, apply, filter])

    const onSubmit = data => {
        const dataSend = {
            quantity: Number(data['quantity-' + rowSelected.id]),
            price: Number(data['normal_price-' + rowSelected.id]) || (rowSelected?.product_variant_id === 0 ? rowSelected.product.price : rowSelected.product_variant.price),
            discount_price: Number(data['discount_price-' + rowSelected.id]) || (rowSelected?.product_variant_id === 0 ? rowSelected.product.discount_price : rowSelected.product_variant.discount_price),
        }

        console.log('Data : ', rowSelected)

        rowSelected.product_variant_id === 0
            ? addProductInventory(storeId, rowSelected.product.id, dataSend)
                .then(res => {
                    enqueueSnackbar(t('InventoryPage.updateSuccess'), successToast)
                    //console.log("res ->", res)
                    isApply(true)
                })
                .catch(err => {
                    console.log('Error ClientPage', err);
                    enqueueSnackbar(t('InventoryPage.updateError'), errorToast)
                })
            : addProductInventoryWithVariant(storeId, rowSelected.product.id, rowSelected.product_variant_id, dataSend)
                .then(res => {
                    enqueueSnackbar(t('InventoryPage.updateSuccess'), successToast)
                    //console.log("res ->", res)
                    isApply(true)
                })
                .catch(err => {
                    console.log('Error ClientPage', err);
                    enqueueSnackbar(t('InventoryPage.updateError'), errorToast)
                });
    }

    return (
        <Grid container>
            {(gettingData && firstLoad) &&
                <Box
                    style={{ height: '90vh', width: '100%' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Box>
            }

            {/* {isExport &&
                <Grid item xs={12} className={classes.notification}>
                    <Grid item container style={{ justifyContent: 'flex-start', display: 'flex', width: 40 }}>
                        <img src={CheckIcon} alt="check" />
                    </Grid>
                    <Grid item container style={{ justifyContent: 'flex-start', display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h5" style={{ color: '#0F2930' }}>
                            Tu inventario se está preparando para la exportación.
                        </Typography>
                        <Typography variant="body1" style={{ color: '#0F2930' }}>
                            Te enviaremos un correo a {userEmail} con el archivo de exportación.
                        </Typography>
                    </Grid>
                    <Grid item container style={{ justifyContent: 'flex-end', display: 'flex', width: 30 }}>
                        <img src={CloseIcon} alt="close" style={{ cursor: 'pointer' }} onClick={() => setExport(false)} />
                    </Grid>
                </Grid>
            } */}

            <Grid item xs={12} className={classes.newContainer}>
                <Grid item container xs={6} style={{ justifyContent: 'flex-start', display: 'flex' }}>
                    <Input
                        className={classes.filterInput}
                        disableUnderline
                        color="primary"
                        margin="normal"
                        placeholder={t('InventoryPage.placeholderSearch')}
                        onChange={handleFilterChange}
                        value={filter}
                        variant="outlined"
                        startAdornment={
                            <InputAdornment position="start">
                                <img src={SearchIcon} alt="Search" />
                            </InputAdornment>
                        }
                    />
                </Grid>

                <Grid item container xs={2} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Button
                        size="small"
                        style={{ marginRight: '16px' }}
                        startIcon={<img src={ImportIcon} alt="" />}
                        onClick={() => history.push('/inventory/import')}
                    >
                        {t('ImportInventory.title')}
                    </Button>
                </Grid>

                <Grid item container xs={2} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Button
                        size="small"
                        style={{ marginRight: '16px' }}
                        startIcon={<img src={ExportIcon} alt="" />}
                        onClick={handleExport}
                    >
                        {t('ExportInventory.title')}
                    </Button>
                </Grid>

                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        className="btnLink"
                        onClick={() => history.push('/products')}
                        color="primary"
                        variant="contained"
                    >
                        {t('InventoryPage.viewProduct')}
                    </Button>
                </Grid>
            </Grid>

            {
                rows.length === 0 && !gettingData
                    ?
                    <Grid item xs={12} style={{ marginTop: 25 }}>
                        <ViewDataEmpty
                            title="Gestiona tu inventario"
                            subtitle="Con ecometri puedes rastrear tu inventario para evitar ventas de productos cuya existencia esté agotada. En este panel se mostrará el inventario de cada uno de los productos."
                            image={ImageDefaultInventory}
                            btnTitle={t('HomePage.productsBtn')}
                            action="/products"
                        >
                        </ViewDataEmpty>
                    </Grid>
                    :
                    <React.Fragment>
                        {(rows.length >= 0 && !firstLoad) &&
                            <React.Fragment>
                                <Grid item xs={12} style={{ marginTop: 25 }}>
                                    <Paper className={classes.root}>
                                        <TableContainer className={isTabletOrMobile ? classes.container : classes.containerDesktop}>
                                            <div style={{ minHeight: '4px' }}>
                                                {gettingData && <LinearProgress />}
                                            </div>
                                            <form onSubmit={handleSubmit(onSubmit)}>

                                                <Table stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            {columns.map((column) => (
                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    style={{
                                                                        minWidth: column.minWidth,
                                                                        display: isTabletOrMobile ? column.mobile ? 'table-cell' : 'none' : 'table-cell',
                                                                    }}>
                                                                    {column.label}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {rows.map((row, index) => {
                                                            return (
                                                                <>
                                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                        <TableCell>
                                                                            <ProductImage
                                                                                style={{ maxWidth: '50px' }}
                                                                                src={row?.product?.product_images[0]?.picture_url ? `${process.env.REACT_APP_IMAGES_API_URL}fit-in/600x600/${row?.product?.product_images[0]?.picture_url}` : `https://ui-avatars.com/api/?name=${row?.product?.name}&size=80&background=CAD7CA&color=fff`}
                                                                                //src={`${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(handleImageBase64()).toString('base64')}`}
                                                                                alt={row?.product?.name}
                                                                            />
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                <Link
                                                                                    color='textSecondary'
                                                                                    variant='button'
                                                                                    onClick={() => console.log('click')}
                                                                                    component={RouterLink}
                                                                                    to={'/products/update/' + row?.product?.id}
                                                                                >
                                                                                    {row?.product?.name}
                                                                                </Link>
                                                                                {row?.product_variant?.variant_options && <Typography variant="body2" color="textSecondary">
                                                                                    {row?.product_variant?.variant_options.map((item, index) => {
                                                                                        return (
                                                                                            <span key={index}>{isNaN(item?.name) ? capitalize(item?.name) : item?.name}, </span>
                                                                                        )
                                                                                    })}
                                                                                </Typography>}
                                                                            </div>
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            {row?.product_variant_id === 0 ? row?.product?.sku : row?.product_variant?.sku}
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            <Typography gutterBottom variant="body1">
                                                                                <Controller
                                                                                    render={({ field }) => (
                                                                                        <CurrencyField
                                                                                            {...field}
                                                                                            setValue={val => setValue(`normal_price-${row.id}`, val)}
                                                                                            style={{ width: '120px', textAlignLast: 'right' }}
                                                                                            defaultValue={row?.product_variant_id === 0 ? row?.product?.price : row?.product_variant?.price}
                                                                                            variant="standard"
                                                                                        />)}
                                                                                    name={`normal_price-${row.id}`}
                                                                                    control={control}
                                                                                //defaultValue={}
                                                                                />
                                                                            </Typography>
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            <Typography gutterBottom variant="body1">
                                                                                <Controller
                                                                                    render={({ field }) => (
                                                                                        <CurrencyField
                                                                                            {...field}
                                                                                            setValue={val => setValue(`discount_price-${row.id}`, val)}
                                                                                            style={{ width: '120px', textAlignLast: 'right' }}
                                                                                            defaultValue={row?.product_variant_id === 0 ? row?.product?.discount_price : row?.product_variant?.discount_price}
                                                                                            variant="standard"
                                                                                        />)}
                                                                                    name={`discount_price-${row.id}`}
                                                                                    control={control}
                                                                                //defaultValue={}
                                                                                />
                                                                            </Typography>
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            {row?.quantity}
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            <TextOnlyTooltip
                                                                                arrow
                                                                                title={t('Product.variants.quantityTooltip')}
                                                                                placement="top-start"
                                                                            >
                                                                                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                                                    <OutlinedInput
                                                                                        {...register(`quantity-${row.id}`)}
                                                                                        defaultValue={row?.quantity}
                                                                                        inputProps={{ min: 0, max: 100 }}
                                                                                        type="number"
                                                                                        variant="outlined"
                                                                                        style={{ width: '80px' }}
                                                                                    />
                                                                                    <IconInfo style={{ width: '30px', fill: colors.primary.dark }}/>
                                                                                </div>
                                                                            </TextOnlyTooltip>
                                                                        </TableCell>

                                                                        <TableCell>
                                                                            <Button
                                                                                onClick={() => setRowSelected(row)}
                                                                                type='submit'
                                                                                color="primary"
                                                                                variant="contained"
                                                                            >
                                                                                {t('InventoryPage.headers.btnSave')}
                                                                            </Button>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </form>
                                        </TableContainer>
                                        <TablePagination
                                            shape="rounded"
                                            variant="outlined"
                                            labelRowsPerPage={t('table.rowsPerPageLabel')}
                                            labelDisplayedRows={({ from, to, count }) => `${t('table.toShow')} ${to} ${t('table.of')} ${count} ${t('table.products')}`}
                                            rowsPerPageOptions={totalRows > 0 ? [10, 25, 50] : []}
                                            component="div"
                                            className={classes.spacer}
                                            count={totalRows}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                </Grid>
                            </React.Fragment>
                        }
                    </React.Fragment>
            }
        </Grid >
    );
}

const mapStateToProps = ({ auth, orders, common }) => ({
    storeId: auth.storeId,
    userEmail: auth.userEmail,
    initialRowsPerPage: orders.rowsPerPage,
    initialPage: orders.page,
    storeData: common.storeData
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setRowsPerPageState,
    setPageState,
    setClientToEdit
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InventoryPage);