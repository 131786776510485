import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DefaultErrorIcon from '../../../assets/icons/error.svg';


import { ContentError, IconError } from './ListError.styles';

export const ListError = ({ title, subTitle, items }) => {
    const { t } = useTranslation();

    const readError = (options) => {
        switch (true) {
            case (options.value === 'File has the wrong format'):
                return t('ImportProducts.errorFileFormat');

            case (options.empty):
                return t('ImportProducts.errorColumnRequired') + options.line + ' columna: ' + options.field + ' ' + t('ImportProducts.errorColumnRequiredCompl');


            case (options.wrong_format):
                return t('ImportProducts.errorColumnFormat') + options.line + ' columna: ' + options.field + ' ' + t('ImportProducts.errorColumnFormatCompl');

            default:
                break;
        }
    }

    return (
        <ContentError>
            <Grid container>
                <Grid item xs={2}>
                    <IconError src={DefaultErrorIcon} />
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="body1">
                        {title} <br></br>
                        {subTitle}
                    </Typography>
                    <ul style={{ padding: 0, paddingLeft: 18 }}>
                        {items.map((x, i) => {
                            return (<li key={i}>{readError(x)}</li>)
                        })}
                    </ul>
                </Grid>
            </Grid>
        </ContentError>
    );
}

export default ListError;