import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useMediaQuery } from 'react-responsive';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { Grid, LinearProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { Intro } from '../../components/ManageCollection/ManageCollection.styles';
import ClientsIcon from '../../assets/icons/clients.svg';
import WPIcon from '../../assets/icons/whatsapp-primary-dark.svg';

import {
    errorToast,
} from '../../constants.js/misc';

import { routes } from '../../constants.js/misc';
import { setClientToEdit } from '../../modules/clients';
import { getClientService } from '../../services/clients';
import ClientStatus from '../../components/ClientInfoPage/ClientStatus/ClientStatus';
import ClientOrders from '../../components/ClientInfoPage/ClientOrders/ClientOrders';
import ClientInfo from '../../components/ClientInfoPage/ClientInfo/ClientInfo';
import ClientNotes from '../../components/ClientInfoPage/ClientNotes/ClientNotes';
import StatePill from '../../components/StatePill';

/**
 * Renders the order info page
 * @param {number} storeId main store id
 */
export const ClientInfoPage = ({ storeId, clientToEdit, setClientToEdit, storeData }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const history = useHistory();
    let { id } = useParams();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });

    // State variables
    const [gettingClient, setGettingClient] = useState(false);
    const [clientData, setClientData] = useState(null);

    /**
     * Get the product data if an id is provided in the url
     */
    useEffect(() => {
        if (id) {

            /**
             * If the clientToEdit variable is set in the state use it to
             * show the info
             */
            if (clientToEdit) {
                setClientData(clientToEdit);
            } else {
                /**
                 * If the variable is not set it means the user got to the page using
                 * only the url, get the store data from the api to show the info
                 */
                setGettingClient(true);
                getClientService(storeId, id)
                    .then(res => {
                        setGettingClient(false);
                        setClientData(res.data);
                    })
                    .catch(err => {
                        setGettingClient(false);
                        enqueueSnackbar(t('OrderDetail.dataError'), errorToast);
                        history.push(routes.clientsPage);
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    /**
     * Clean the saved product data after unmount
     */
    useEffect(() => () => setClientToEdit(null), []);

    const message = `Hola, ${clientData?.first_name} ${clientData?.last_name},
Te contactamos de ${storeData?.name}
`;

    const handleClickContact = () => {
        const messageUrl = `https://wa.me/${clientData?.phone_number}?text=`
        window.open(`${messageUrl}${encodeURIComponent(message).replace(/%20/g, '+')}`)
    }

    return (
        <Grid container spacing={isTabletOrMobile ? 0 : 3}>
            {isTabletOrMobile &&
                <Intro item container xs={12}>
                    <img src={ClientsIcon} alt='ClientsIcon' />
                    <Typography variant="caption">
                        {t('ClientTitle')}
                    </Typography>
                </Intro>
            }

            <Grid item xs={12} style={{ margin: isTabletOrMobile ? 10 : 0 }}>
                <Grid item xs={12}>
                    <Grid container
                        alignItems="center"
                        justifyContent="center">
                        <Grid item xs={9}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <Typography variant="h4" style={{ marginRight: '6px' }}>
                                    {clientData ? `${clientData?.first_name} ${clientData?.last_name}` : ''}
                                </Typography>
                                {clientData?.lead && <StatePill state={clientData?.lead?.name} type={1} />}
                                {clientData?.frequency && <StatePill state={clientData?.frequency?.name} type={2} />}
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                fullWidth
                                type='button'
                                variant="contained"
                                onClick={handleClickContact}
                                color="primary"
                                startIcon={<img src={WPIcon} alt='WPIcon' />}>
                                {t('ClientDetail.contactBtn')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Link
                        component={RouterLink}
                        to={routes.clientPage}>
                        ‹ {t('ClientTitle')}
                    </Link>
                    {gettingClient && <LinearProgress />}
                </Grid>
            </Grid>
            {(!gettingClient && clientData) &&
                <>
                    <Grid item xs={12} lg={7}>
                        <ClientStatus quantity={clientData?.quantity} total={clientData?.total} />
                        <ClientOrders orders={clientData?.orders} />
                    </Grid>
                    <Grid item xs={12} lg={5} style={{ marginBottom: isTabletOrMobile ? 100 : 0 }}>
                        <ClientInfo
                            clientId={clientData?.id}
                            fullname={`${clientData?.first_name} ${clientData?.last_name}`}
                            first_name={clientData?.first_name}
                            last_name={clientData?.last_name}
                            phone_number={clientData?.phone_number}
                            email={clientData?.email}
                            dni={clientData?.dni}
                            document_type={clientData?.document_type}
                            address={clientData?.address}
                            address_detail={clientData?.address_line}
                            city_id={clientData?.city_id}
                            city={clientData?.city}
                            handleClickContact={handleClickContact}
                        />
                        <ClientNotes clientId={clientData?.id} notes={clientData?.notes} />
                    </Grid>
                </>
            }
        </Grid >
    );
}

const mapStateToProps = ({ auth, orders, common }) => ({
    storeId: auth.storeId,
    clientToEdit: orders.clientToEdit,
    storeData: common.storeData
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setClientToEdit
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientInfoPage);