import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';

import MobileCategories from '../../components/MobileCategories/MobileCategories';
import Header from '../../components/MobileUX/Header/Header';
import ProductMobile from '../../components/MobileUX/ProductMobile/ProductMobile';
import ViewDataEmpty from '../../components/ViewDataEmpty/ViewDataEmpty';
import ImageDefaultProduct from '../../assets/img/default/onboarding_products.jpg';
import EditIcon from '../../assets/icons/edit-collection.svg';



// Internal dependencies
import { routes } from '../../constants.js/misc';
import { setStoreData } from '../../modules/common';
import { setActiveCollection } from '../../modules/collections';
import {
    getProductsServiceMobile
} from '../../services/products';

// import { PlanInfoBar } from '../../components/BillingPage/WizardSelectPlan/PlanInfoBar/PlanInfoBar' // Barra de información del trial

import {
    drawerStyles
} from '../../containers/AppContainer/AppContainer.styles';

export const HomePageMobile = ({ storeId, storeData, setActiveCollection, activeCollection }) => {
    const { t } = useTranslation();
    const [dataProducts, setDataProducts] = useState([]);
    const [getttingProduct, setGettingProduct] = useState();
    const showLoader = storeData.id ? false : true;
    const classes = drawerStyles();

    const [statusPlan, setStatusplan] = useState(null)
    const [daysToPay, setDaysToPay] = useState(null)
    const [paymentStatus, setPaymentStatus] = useState(null)


    useEffect(() => {
        setGettingProduct(true);
        getProductsServiceMobile(storeId)
            .then(({ data }) => {
                setGettingProduct(false);
                setDataProducts(data);
            })
    }, [storeId]);
    useEffect(() => {
        if (storeData) {
            if (storeData.store_plan) {
                setStatusplan(storeData.store_plan.store_plan_status_id)
                setDaysToPay(storeData.store_plan.days_to_pay)
                setPaymentStatus(storeData.store_plan.store_plan_payment_status_id)
            }
        }
    }, [storeData])

    return (
        <div style={{ marginBottom: 100 }}>
            {/* <PlanInfoBar
                    paymentStatus={paymentStatus}
                    statusPlan={statusPlan}
                    daysToPay={daysToPay}
                    storeId={storeId}
                /> */}
            <Header
                loading={showLoader}
                storeNumber={storeData.userMobileNumber}
                shopTitle={storeData.name}
                inactiveForPay={storeData && !storeData.active ? true : false}
                shopLogo={storeData.profile_picture}
                shopDescription={storeData.description}
                shopCover={storeData.banner_picture}
                verified={storeData.verified}
                shopType={storeData.minimumCartValue} />
            <div className={storeData && !storeData.active ? classes.inactiveForPay : ''}>
                <MobileCategories />
            </div>


            <Grid item container xs={12}>

                {getttingProduct &&
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        minHeight: 104
                    }}>
                        <CircularProgress />
                    </div>
                }
                {dataProducts.length === 0 && !getttingProduct &&
                    <>
                        <Grid item xs={12}>
                            <ViewDataEmpty 
                                title="Agregar y gestionar tus productos"
                                subtitle="En tu ecometri puedes subir productos ilimitados para crear un catálogo completo de WhatsApp. Sube tus productos y agrégalos a tus categorías para que puedas organizarlos correctamente."
                                btnTitle={t('ProductsTable.addNew')}
                                action={routes.createProduct}
                                isMobileProduct={true}
                                image={ImageDefaultProduct}>
                            </ViewDataEmpty>
                        </Grid>
                    </>
                }
                {
                    dataProducts && dataProducts
                        .filter(product => product.collections.map(collection => collection.collection_id).indexOf(activeCollection) !== -1)
                        .map((product) => {
                            return (
                                <Grid item xs={6} className={storeData && !storeData.active ? classes.inactiveForPay : ''}>
                                    <ProductMobile
                                        currency={storeData.currency}
                                        loading={getttingProduct}
                                        key={product.id}
                                        alt={product.name}
                                        url={routes.updateProduct + '/' + product.id}
                                        thumbnail={
                                            product.product_images.length > 0 ? (
                                                product.product_images[0].picture_url
                                            ) : ``
                                        }
                                        productName={product.name}
                                        productPrice={product.price}
                                        productDiscountPrice={product.discount_price} />
                                </Grid>
                            )
                        })
                }
            </Grid>
        </div >
    );
}

const mapStateToProps = ({ auth, common, collections }) => ({
    storeId: auth.storeId,
    storeData: common.storeData,
    activeCollection: collections.activeCollection
});


const mapDispatchToProps = dispatch => bindActionCreators({
    setStoreData,
    setActiveCollection
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(HomePageMobile);