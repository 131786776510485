// React and external dependencies
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import QRCode from "qrcode"

// UI material dependencies
import { AddCircle as AddCircleIcon, Link as LinkIcon } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

//assets
import { IconEdit } from '../../assets/icons/FuncEditIcon'
import { IconDelete } from '../../assets/icons/funcDeleteIcons'

// Internal dependencies
import { errorToast, successToast } from '../../constants.js/misc';
import { getUserStoresService, getStoreService, getStoreLimits } from '../../services/store';
import { MainUserStore } from '../../components/MainUserStore/MainUserStore';
import { routes } from '../../constants.js/misc';
import { setUserStoreToEdit, setPageState, setRowsPerPageState } from '../../modules/user-stores';
import useDebounce from '../../utils/debounce';
import { hrefTo } from '@storybook/addon-links';
import { 
    QuickActions, 
    UserStoreLink 
} from './UserStoresTablePage.style'

import { deleteUserStoreService } from '../../services/store'


import { setMainUserStoreService } from '../../services/store'

const useStyles = makeStyles({
    root: { width: '100%' },
    container: {
        maxHeight: '400px',
    },
});


export const UserStoresTablePage = ({ storeId, initialPage, initialRowsPerPage }) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const classes = useStyles();

    const [gettingData, setGettingData] = useState(true);
    const [checkLimitUserStore, setCheckLimitUserStore] = useState(true);
    const [page, setPage] = useState(initialPage);
    const [rows, setRows] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
    const [totalRows, setTotalRows] = useState(0);
    const [storeData, setStoreData] = useState({});
    const [filter, setFilter] = useState('');
    const [downloadQR, setDownloadQR] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false)
    const [idRow, setIdRow] = useState(null)

    const debouncedFilter = useDebounce(filter, 500);

    const columns = [
        { id: 'name', label: t('UserStores.headers.name'), minWidth: 170 },
        { id: 'phone', label: t('UserStores.headers.phone'), minWidth: 100 },
        { id: 'link', label: t('UserStores.headers.link'), minWidth: 100 },
        { id: 'QrLine', label: t('userStores.headers.QR'), minWidth: 100 },
        { id: 'actions', label: '', minWidth: 50 },
    ];

    /**
     * Sets the current page
     * @param {Event} event native event, not used
     * @param {number} newPage page to show
     */
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setPageState(newPage);
    };

    /**
     * Sets the rows per page variable and goes back
     * to the first page
     * @param {Event} event  native click event
     */
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setPageState(0);
        setRowsPerPage(+event.target.value);
        setRowsPerPageState(+event.target.value);
    };

    const handleFilterChange = ({ target }) => setFilter(target.value);

    // delete User_Store 
    const deleteUserStore = (id) => {
        if (id) {
           if (storeData?.default_user_store){
                if (storeData?.default_user_store?.id === id ){
                    const user = rows.filter( us => us.id !== storeData?.default_user_store?.id )
                    setRows(user)
                    setMainUserStoreService(storeId, user[0]?.id, user[0])
                    setDialogOpen(false)
                    deleteUserStoreService(storeId, id)
                    .then(() => {
                        enqueueSnackbar(t('UserStore.deleteSuccess'), successToast)
                    })
                    .catch(() => {
                        enqueueSnackbar(t('UserStore.deleteError'), errorToast)
                    })
                }else{
                    const user = rows.filter( us => us.id !== id )
                    setRows(user)
                    setDialogOpen(false)
                    deleteUserStoreService(storeId, id)
                    .then(() => {
                        enqueueSnackbar(t('UserStore.deleteSuccess'), successToast)
                    })
                    .catch(() => {
                        enqueueSnackbar(t('UserStore.deleteError'), errorToast)
                    })
                }
            }
        }
    }
    /**
     * Copies the user store link to the clipboard
     * @param {number} phone user store phone
     */
    const copyLink = (phone) => {
        document.addEventListener('copy', (e) => {
            e.clipboardData.setData('text/plain', (`https://${storeData.domain ? storeData.domain : (`${storeData.url}.ecometri.shop`)}/${phone}`));
            e.preventDefault();
            document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
        enqueueSnackbar(t('UserStores.copied'), successToast);
    };
    
    const QrClick = async (phone) => {
        // Var production
        const y = `https://${storeData.domain ? storeData.domain : (`${storeData.url}.ecometri.shop`)}/${phone}`
       
        // // var development
        // const y = `https://${storeData.url}.l10s.co/${phone}`

       const res = await QRCode.toDataURL(y, { errorCorrectionLevel: 'H' })
       setDownloadQR(res) 
    };
    
    useEffect(() => {
        if (rows.length > 0) {
            getStoreLimits(storeId)
                .then(({ data }) => {
                    if (rows.length <= data.included_user_stores) {
                        setCheckLimitUserStore(false);
                    } else {
                        setCheckLimitUserStore(true);
                    }
                    setGettingData(false);
                })
                .catch(err => {
                    console.log('Error UserStoresTablePage', err);
                    setGettingData(false);
                    enqueueSnackbar(t('UserStores.tableError'), errorToast)
                });
        } else {
            setCheckLimitUserStore(false);
        }
    }, [rows]);
    
    useEffect(() => {
        getStoreService(storeId)
            .then((res) => setStoreData(res.data));
    }, []);

    useEffect(() => {
        setGettingData(true);
        getUserStoresService(storeId, rowsPerPage, page, debouncedFilter)
            .then(res => {
                setTotalRows(+res.headers['x-total-count'] || 12);
                setRows(res.data)
                setGettingData(false);
            })
            .catch(err => {
                console.log('Error UserStoresTablePage', err);
                setGettingData(false);
                enqueueSnackbar(t('UserStores.tableError'), errorToast)
            });
    }, [storeId, rowsPerPage, page, enqueueSnackbar, t, debouncedFilter]);

    useEffect(() => {
        getStoreService(storeId)
            .then((res) => setStoreData(res.data));
        }, []);
        
        return (
            <Grid container>
            {!isTabletOrMobile &&
                <Grid item container xs={12} style={{ marginBottom: '20px', justifyContent: 'flex-end', display: 'flex' }}>
                    <Button
                        component={RouterLink}
                        to={routes.createUserStore}
                        color="primary"
                        disabled={checkLimitUserStore}
                        variant="contained"
                        startIcon={<AddCircleIcon />}
                        >
                        {t('UserStores.AddNew')}
                    </Button>
                </Grid>
            }
            <Grid item xs={12} style={{ marginBottom: '20px' }}>
                <Typography variant="h5">{t('UserStores.Title')}</Typography>
                <Typography variant="body2">{t('UserStores.intro')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.root}>
                    <TableContainer className={classes.container}>
                        <div style={{ minHeight: '4px' }}>
                            {gettingData && <LinearProgress />}
                        </div>
                        <div style={{ padding: '1rem' }}>
                            <TextField
                                placeholder={t('UserStores.filter')}
                                onChange={handleFilterChange}
                                value={filter}
                                variant="outlined" />
                        </div>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}>
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id + index}>
                                            <TableCell>
                                                <Link
                                                    variant='button'
                                                    color='textSecondary'
                                                    onClick={() => setUserStoreToEdit(row)}
                                                    component={RouterLink}
                                                    to={'/user-stores/update/' + row.id}>
                                                    {row.name}
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                {row.mobile_phone_number}
                                            </TableCell>
                                            <TableCell>
                                                <UserStoreLink onClick={() => copyLink(row.mobile_phone_number)}>
                                                    <LinkIcon />
                                                    {t('UserStores.copyLink')}
                                                </UserStoreLink>
                                            </TableCell>
                                            <TableCell>
                                                <UserStoreLink onClick={() => QrClick(row.mobile_phone_number)} href={downloadQR} download>
                                                    <GetAppIcon />
                                                    {t('products.QRCodeButtonMobile')}
                                                </UserStoreLink>
                                            </TableCell>
                                            <TableCell>
                                                <QuickActions>
                                                <Link
                                                    color='textSecondary'
                                                    onClick={() => setUserStoreToEdit(row)}
                                                    component={RouterLink}
                                                    to={'/user-stores/update/' + row.id}>
                                                     <IconEdit style={{ width: '20px', height: '20px', cursor: 'pointer' }}/>
                                                </Link>
                                                   
                                                    {rows.length > 1 &&
                                                        <IconDelete
                                                            style={{ width: '20px', height: '20px', cursor: 'pointer' }} 
                                                            onClick={() => {
                                                                setDialogOpen(true)
                                                                setIdRow(row.id)
                                                            }}
                                                        />
                                                    }
                                                </QuickActions>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        shape="rounded"
                        variant="outlined"
                        labelRowsPerPage={t('table.rowsPerPageLabel')}
                        labelDisplayedRows={({ from, to, count }) => `${t('table.toShow')} ${to} ${t('table.of')} ${count} ${t('table.lines')}`}
                        rowsPerPageOptions={totalRows > 0 ? [10, 25, 50] : []}
                        component="div"
                        count={totalRows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
                <MainUserStore storeId={storeId} storeData={storeData} userMain={rows[0]}/>
            </Grid>
            {isTabletOrMobile &&
                <Grid item container xs={12} style={{ marginBottom: '20px', justifyContent: 'flex-end', display: 'flex' }}>
                    <Button
                        style={{ width: '100%' }}
                        component={RouterLink}
                        to={routes.createUserStore}
                        color="primary"
                        disabled={checkLimitUserStore}
                        variant="contained"
                        startIcon={<AddCircleIcon />}
                    >
                        {t('UserStores.AddNew')}
                    </Button>
                </Grid>
            }
                <Dialog
                    open={dialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{t('UserStore.deleteTitle')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t('UserStore.deleteMessage')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setDialogOpen(false)} color="primary">
                            {t('UserStore.deleteCancel')}
                        </Button>
                        <Button onClick={()=> deleteUserStore(idRow)} color="primary" autoFocus>
                            {t('UserStore.deleteConfirm')}
                        </Button>
                    </DialogActions>
                </Dialog>
        </Grid>
    );
}

const mapStateToProps = ({ auth, userStores }) => ({
    storeId: auth.storeId,
    userStoreToEdit: userStores.userStoreToEdit,
    initialRowsPerPage: userStores.rowsPerPage,
    initialPage: userStores.page
});

const mapDispatchToProps = dispatch => bindActionCreators({ setUserStoreToEdit }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserStoresTablePage);