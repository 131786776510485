import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { Button } from '@material-ui/core';

// Internal dependencies
import { routes, formatNumber } from '../../../constants.js/misc';


/**
 * Renders the orders table
 * @param {function} setOrderToEditsss sets the orderToEdit object on the state
 */

const ClientOrderTable = ({ orderDetails, storeData }) => {
    const { t } = useTranslation();

    const totalWithShipping = (orderShippingCost) => {
        return orderShippingCost
            ? (isNaN(orderShippingCost)
                ? ((isNaN(storeData.delivery_cost_details) || !storeData.delivery_cost_enabled) ? 0 : parseInt(storeData.delivery_cost_details))
                : orderShippingCost
            )
            : (isNaN(storeData.delivery_cost_details) || !storeData.delivery_cost_enabled) ? 0 : parseInt(storeData.delivery_cost_details);
    }

    return (
        <Table stickyHeader={true}>
            <TableHead /* style={{ height: '35px!important' }} */>
                <TableRow>
                    <TableCell>{t('ClientOrdersTablePage.orders.headers.order')}</TableCell>
                    <TableCell>{t('ClientOrdersTablePage.orders.headers.date')}</TableCell>
                    <TableCell>{t('ClientOrdersTablePage.orders.headers.orderValue')}</TableCell>
                    <TableCell>{t('ClientOrdersTablePage.orders.headers.line')}</TableCell>
                    <TableCell>{t('ClientOrdersTablePage.orders.headers.orderStatus')}</TableCell>
                    <TableCell>{t('ClientOrdersTablePage.orders.headers.deliveryStatus')}</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {orderDetails?.map(orderDetail =>
                    <TableRow key={orderDetail?.id}>
                        <TableCell>
                            <Link
                                component={RouterLink}
                                to={routes.ordersPage + '/' + orderDetail.id}
                                color="textSecondary"
                                variant='button'
                            >
                                    
                                #{orderDetail.sequence}
                            </Link>
                        </TableCell>
                        <TableCell>{new Date(orderDetail.created_at).toLocaleDateString()}</TableCell>
                        <TableCell>{formatNumber(orderDetail.order_details.reduce((acc, detail) => acc + (detail.price * detail.quantity), 0) + totalWithShipping(orderDetail.shipping_cost), storeData.currency)}</TableCell>
                        {/* <TableCell style={{ color: '#48AC98', fontWeight: 700 }}>{orderDetail?.user_store?.mobile_phone_number || orderDetail?.user_store_id}</TableCell> */}
                        <TableCell>
                            {orderDetail.user_store ?
                                <Link
                                    style={{ fontWeight: 700 }}
                                    color='textSecondary'
                                    component={RouterLink}
                                    to={routes.updateUserStore + '/' + orderDetail.user_store.id}>
                                    {orderDetail.user_store.mobile_phone_number}
                                </Link> :
                                <Typography
                                    variant="caption"
                                    style={{ fontWeight: 700 }}
                                    color='textSecondary'
                                    >
                                    <em>Sucursal eliminada</em>
                                </Typography>
                            }
                        </TableCell>
                        <TableCell>{orderDetail?.order_status?.name}</TableCell>
                        <TableCell>{orderDetail?.shipping_order_status?.name}</TableCell>
                        <TableCell>
                            <Button
                                component={RouterLink}
                                to={routes.ordersPage + '/' + orderDetail.id}
                                variant="contained"
                                color="primary"
                                style={{whiteSpace: 'nowrap'}}
                            >{t('OrdersTablePage.headers.btnOrder')}
                            </Button>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

const mapStateToProps = ({ common }) => ({
    storeData: common.storeData
});

export default connect(
    mapStateToProps,
)(ClientOrderTable);