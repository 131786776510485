// React dependencies
import React, { useEffect, useState, useRef } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

// External dependencies
import { useSnackbar } from 'notistack';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

// UI material dependencies
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    CircularProgress,
    ClickAwayListener,
    Divider,
    Grid,
    Grow,
    Input,
    InputAdornment,
    LinearProgress,
    Link,
    MenuItem,
    MenuList,
    OutlinedInput,
    Paper,
    Popper,
    Slider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReplayIcon from '@material-ui/icons/Replay';
import { makeStyles } from '@material-ui/core/styles';

// Internal components
import ViewDataEmpty from '../../components/ViewDataEmpty/ViewDataEmpty';

// Internal dependencies
import { errorToast, formatNumber, routes } from '../../constants.js/misc';
//import { setOrderToEdit, setPageState, setRowsPerPageState } from '../../modules/orders';
import { setClientToEdit, setPageState, setRowsPerPageState } from '../../modules/clients';
import ImageDefaultClients from '../../assets/img/default/EmptyClients.png';

// Assets
import FilterIcon from '../../assets/icons/FilterIcon.svg';
import SearchIcon from '../../assets/icons/SearchIcon.svg';
import ExportIcon from '../../assets/icons/ExportIcon.svg';
import { getClientFilters, getClientList } from '../../services/clients';

const useStyles = makeStyles({
    root: { width: '100%' },
    container: {
        maxHeight: '570px',
    },
    newContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%'
    },
    containerDesktop: {
        maxHeight: '560px',
    },
    gridContainer: {
        "& div": {
            maxWidth: 'inherit',
            padding: '8px'
        },
    },
    btn: {
        "& .MuiButton-label": {
            fontSize: 14
        },
        "& .MuiButton-contained": {
            background: '#fff'
        }
    },
    btnFilter: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'center',
        margin: '8px 8px 0px 8px',

        "&:disabled": {
            backgroundColor: '#F6F6F6',
            color: '#8181A5',
        },
    },
    filterInput: {
        backgroundColor: '#fff',
        borderRadius: 8,
        boxShadow: '0px 0px 2px rgba(52, 58, 67, 0.1), 0px 1px 2px rgba(52, 58, 67, 0.08), 0px 1px 4px rgba(52, 58, 67, 0.08)',
        color: '#53627C',
        height: 42,
        padding: '14px 16px',
        width: '100%',
    }
});

/**
 * Renders the orders table
 * @param {number} storeId store id
 * @param {number} initialPage initial page to show
 * @param {number} initialRowsPerPage initial rows per page to show
 * @param {function} setClientToEdit sets the orderToEdit object on the state
 * @param {function} setPageState sets the page to show on the state
 * @param {function} setRowsPerPageState sets the rows per page the state
 */
export const ClientsPage = ({
    storeId,
    initialPage,
    initialRowsPerPage,
    setClientToEdit,
    setPageState,
    setRowsPerPageState,
    storeData
}) => {
    // CUSTOM HOOKS
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    //const history = useHistory();
    const classes = useStyles();

    // USESTATES
    const [gettingData, setGettingData] = useState(true);
    const [page, setPage] = useState(initialPage);
    const [rows, setRows] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
    const [totalRows, setTotalRows] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [apply, isApply] = useState(false);

    const [filter, setFilter] = useState('');

    const [value, setValue] = useState([0, 0]);

    const [maxOrder, setMaxOrder] = useState(0);

    const [statusRate, setStatusRate] = useState([]);
    const [statusFrecuency, setStatusFrecuency] = useState([]);

    const [selectedRate, setSelectedRate] = useState([]);
    const [selectedFrecuency, setSelectedFrecuency] = useState([]);
    const [selectedValue, setSelectedValue] = useState({ min: 0, max: 0 });
    const [selectedOrder, setSelectedOrder] = useState({ min: 0, max: 0 });

    const [dataRate, setRateClient] = useState([]);
    const [dataFrecuency, setFrecuencyClient] = useState([]);

    const [openRate, setOpenRate] = useState(false);
    const [openFrecuency, setOpenFrecuency] = useState(false);
    const [openValue, setOpenValue] = useState(false);
    const [openOrder, setOpenOrder] = useState(false);

    const anchorRefRate = useRef(null);
    const anchorRefFrecuency = useRef(null);
    const anchorRefValue = useRef(null);
    const anchorRefOrder = useRef(null);

    const columns = [
        { id: 'name', label: t('ClientPage.filter.name'), mobile: true },
        { id: 'phone', label: t('ClientPage.filter.phone'), mobile: true },
        //{ id: 'rate', label: t('ClientPage.filter.rate'), mobile: true },
        //{ id: 'frecuency', label: t('ClientPage.filter.frecuency'), mobile: true },
        { id: 'order', label: t('ClientPage.filter.order'), mobile: true },
        { id: 'value', label: t('ClientPage.filter.value'), mobile: true },
        { id: 'actions', label: t('ClientPage.filter.action'), mobile: true },
    ];

    // FUNCTIONS
    const handleSendWhatsapp = (name, phone) => {
        const messageUrl = `https://wa.me/${phone}?text=`;
        const message = `Hola, ${name}, Te contactamos de ${storeData?.name}`;

        window.open(`${messageUrl}${encodeURIComponent(message).replace(/%20/g, '+')}`)
    }

    const getHighestOrder = (rows) => {
        let highestOrder = 0;
        rows.forEach(row => {
            if (row.quantity > highestOrder) {
                highestOrder = row.quantity;
            }
        });
        highestOrder > maxOrder && setMaxOrder(highestOrder);
    }

    // HANDLECLICK
    const handleClickRate = () => {
        setOpenRate(openRate ? false : true);
    };

    const handleClickFrecuency = () => {
        setOpenFrecuency(openFrecuency ? false : true);
    };

    const handleClickValue = () => {
        setOpenValue(openValue ? false : true);
    };

    const handleClickOrder = () => {
        setOpenOrder(openOrder ? false : true);
    };

    const handleClickClearFilter = () => {
        setSelectedValue({ min: 0, max: 0 })
        setSelectedOrder({ min: 0, max: 0 })
        setSelectedFrecuency([]);
        setStatusFrecuency([]);
        setSelectedRate([]);
        setStatusRate([]);
        isApply(true);
        setPage(0);
    };

    // HANDLECLOSE
    const handleCloseRate = (event) => {
        if (anchorRefRate.current && anchorRefRate.current.contains(event.target)) {
            return;
        }
        setOpenRate(false);
    };

    const handleCloseFrecuency = (event) => {
        if (anchorRefFrecuency.current && anchorRefFrecuency.current.contains(event.target)) {
            return;
        }
        setOpenFrecuency(false);
    };

    const handleCloseValue = (event) => {
        if (anchorRefValue.current && anchorRefValue.current.contains(event.target)) {
            return;
        }
        setOpenValue(false);
    };

    const handleCloseOrder = (event) => {
        if (anchorRefOrder.current && anchorRefOrder.current.contains(event.target)) {
            return;
        }
        setOpenOrder(false);
    };

    // HANDLECHANGE
    const handleChangePage = (e, newPage) => {
        setPage(newPage);
        setPageState(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setPageState(0);
        setRowsPerPage(+event.target.value);
        setRowsPerPageState(+event.target.value);
    };

    const handleFilterChange = ({ target }) => {
        setFilter(target.value);
        setPage(0);
    };

    const handleChange = (event, newValue) => {
        setSelectedOrder({ min: newValue[0], max: newValue[1] });
        setValue(newValue);
    }

    // HANDLECHECK
    const handleCheckRate = (check, status, name) => {
        check
            ? setSelectedRate([...selectedRate, { id: status, name: name }])
            : selectedRate.find(item => item.id !== status) !== undefined ? setSelectedRate([selectedRate.find(item => item.id !== status)]) : setSelectedRate([]);

        check
            ? setStatusRate([...statusRate, status])
            : statusRate.splice(statusRate.indexOf(status), 1);

        setPage(0);
    };

    const handleCheckFrecuency = (check, status, name) => {
        check
            ? setSelectedFrecuency([...selectedFrecuency, { id: status, name: name }])
            : selectedFrecuency.find(item => item.id !== status) !== undefined ? setSelectedFrecuency([selectedFrecuency.find(item => item.id !== status)]) : setSelectedFrecuency([]);

        check
            ? setStatusFrecuency([...statusFrecuency, status])
            : statusFrecuency.splice(statusFrecuency.indexOf(status), 1);

        setPage(0);
    };

    // USEEFFECT
    useEffect(() => {
        setGettingData(true);
        getClientFilters(storeId)
            .then(res => {
                setRateClient(res?.data?.leads_counter)
                setFrecuencyClient(res?.data?.frequencies_counter)
                setGettingData(false);
                setFirstLoad(false);
            })
            .catch(err => {
                console.log('Error Client Page', err);
                setGettingData(false);
                setFirstLoad(false);
            });
    }, [storeId]);

    useEffect(() => {
        setGettingData(true);
        isApply(false)
        getClientList(
            storeId, // -> storeId
            rowsPerPage, // -> limit
            page,  // -> page
            filter.replace(/\+/g, ' ').trim(),  // -> search
            selectedOrder?.max !== 0 ? `${selectedOrder?.min},${selectedOrder?.max}` : '', // -> order
            selectedValue?.max !== 0 ? `${selectedValue?.min},${selectedValue?.max}` : '', // -> value
            selectedRate.length > 0 ? selectedRate.map(item => item.id).join(',') : '', // -> rate
            selectedFrecuency.length > 0 ? selectedFrecuency.map(item => item.id).join(',') : '', // -> frequency
        )
            .then(res => {
                setTotalRows(+res.headers['x-total-count'] || 12);
                setRows(res.data.map(item => ({
                    ...item,
                    showProducts: false
                })));
                getHighestOrder(res.data.map(item => ({
                    ...item,
                    showProducts: false
                })));
                setGettingData(false);
                setFirstLoad(false);
            })
            .catch(err => {
                console.log('Error ClientPage', err);
                setGettingData(false);
                setFirstLoad(false);
                enqueueSnackbar(t('ClientPage.tableError'), errorToast)
            });
    }, [storeId, rowsPerPage, page, enqueueSnackbar, t, apply, filter])

    return (
        <Grid container>
            {(gettingData && firstLoad) &&
                <Box
                    style={{ height: '90vh', width: '100%' }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <CircularProgress />
                </Box>
            }

            <Grid item xs={12} className={classes.newContainer}>
                <Grid item container xs={8} style={{ marginBottom: '20px', justifyContent: 'flex-start', display: 'flex' }}>
                    <Input
                        className={classes.filterInput}
                        disableUnderline
                        color="primary"
                        margin="normal"
                        placeholder={t('ClientsDetail.placeholderSearch')}
                        onChange={handleFilterChange}
                        value={filter}
                        variant="outlined"
                        startAdornment={
                            <InputAdornment position="start">
                                <img src={SearchIcon} alt="Search" />
                            </InputAdornment>
                        }
                    />
                </Grid>

                {/* <Grid item container xs={4} style={{ marginBottom: '20px', justifyContent: 'flex-end', display: 'flex' }}>
                    <Button
                        size="small"
                        style={{ marginRight: '16px' }}
                        startIcon={<img src={ExportIcon} alt="" />}
                    >
                        {t('ExportList.title')}
                    </Button>
                </Grid> */}

            </Grid>

            <Grid item container xs={12} style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 27 }}>
                <Typography
                    variant="body2"
                    style={{ margin: 8 }}
                >
                    <img style={{ marginRight: 5 }} src={FilterIcon} alt="" />
                    {t('Product.filter.by')}
                </Typography>

                <div className={isTabletOrMobile && classes.gridContainer}>
                    <ButtonGroup className={classes.btn} variant="contained" disableElevation={true}>
                        {/* RATE */}
                        {/* <Button
                            onClick={handleClickRate}
                            endIcon={<ExpandMoreIcon />}
                            ref={anchorRefRate}
                            aria-controls={openRate ? 'split-button-categorie' : undefined}
                            aria-expanded={openRate ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                        >
                            {selectedRate.length > 0
                                ? selectedRate.map(item => item?.name).join(', ')
                                : t('ClientPage.filter.rate')
                            }
                        </Button> */}

                        {/* FRECUENCY */}
                        {/* <Button
                            onClick={handleClickFrecuency}
                            endIcon={<ExpandMoreIcon />}
                            ref={anchorRefFrecuency}
                            aria-controls={openFrecuency ? 'split-button-menu' : undefined}
                            aria-expanded={openFrecuency ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                        >
                            {selectedFrecuency.length > 0
                                ? selectedFrecuency.map(item => item?.name).join(', ')
                                : t('ClientPage.filter.frecuency')
                            }
                        </Button> */}

                         {/* ORDER */}
                         <Button
                            onClick={handleClickOrder}
                            endIcon={<ExpandMoreIcon />}
                            ref={anchorRefOrder}
                            aria-controls={openOrder ? 'split-button-menu' : undefined}
                            aria-expanded={openOrder ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                        >
                            {selectedOrder?.min || selectedOrder?.max > 0
                                ? `${selectedOrder?.min}, ${selectedOrder?.max}`
                                : t('ClientPage.filter.order')
                            }
                        </Button>
                       
                        {/* VALUE */}
                        <Button
                            onClick={handleClickValue}
                            endIcon={<ExpandMoreIcon />}
                            ref={anchorRefValue}
                            aria-controls={openValue ? 'split-button-menu' : undefined}
                            aria-expanded={openValue ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                        >
                            {selectedValue?.min || selectedValue?.max > 0
                                ? `${formatNumber(selectedValue?.min)}, ${formatNumber(selectedValue?.max)}`
                                : t('ClientPage.filter.value')
                            }
                        </Button>

                        {/* CLEAR FILTER */}
                        <Button
                            onClick={handleClickClearFilter}
                            disabled={selectedRate?.length > 0 || selectedFrecuency?.length > 0 || selectedValue?.max > 0 || selectedOrder?.max > 0 || selectedValue?.min > 0 || selectedOrder?.min > 0 ? false : true}
                            startIcon={<ReplayIcon />}
                        >
                            {t('Product.filter.empty')}
                        </Button>
                    </ButtonGroup>
                </div>

                {/* POPPER RATE */}
                <Popper style={{ zIndex: 99 }} open={openRate} anchorEl={anchorRefRate.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                marginTop: 10,
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseRate}>
                                    <MenuList id="split-button-menu" style={{ display: 'flex', flexDirection: 'column' }}>
                                        {dataRate.map((option, index) => (
                                            <React.Fragment>
                                                <Grid item container xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }} key={option?.id}>
                                                    <Checkbox
                                                        checked={statusRate.includes(option?.id)}
                                                        onClick={
                                                            () => handleCheckRate(
                                                                statusRate.includes(option?.id) ? false : true,
                                                                option?.id,
                                                                option?.name
                                                            )
                                                        }
                                                    />
                                                    <MenuItem>
                                                        {`${option?.name} (${option?.quantity})`}
                                                    </MenuItem>
                                                </Grid>
                                                <Divider />
                                            </React.Fragment>
                                        ))}
                                        <Button
                                            onClick={() => isApply(true)}
                                            color="primary"
                                            variant="contained"
                                            className={classes.btnFilter}
                                            disabled={statusRate.length === 0 ? true : false}
                                        >
                                            Aplicar
                                        </Button>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>

                {/* POPPER FRECUENCY */}
                <Popper style={{ zIndex: 99 }} open={openFrecuency} anchorEl={anchorRefFrecuency.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                marginTop: 10,
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseFrecuency}>
                                    <MenuList id="split-button-menu" style={{ display: 'flex', flexDirection: 'column' }}>
                                        {dataFrecuency.map((option, index) => (
                                            <React.Fragment>
                                                <Grid item container xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }} key={option?.id}>
                                                    <Checkbox
                                                        checked={statusFrecuency.includes(option?.id)}
                                                        onClick={
                                                            () => handleCheckFrecuency(
                                                                statusFrecuency.includes(option?.id) ? false : true,
                                                                option?.id,
                                                                option?.name
                                                            )
                                                        }
                                                    />
                                                    <MenuItem>
                                                        {`${option?.name} (${option?.quantity})`}
                                                    </MenuItem>
                                                </Grid>
                                                <Divider />
                                            </React.Fragment>
                                        ))}
                                        <Button
                                            onClick={() => isApply(true)}
                                            color="primary"
                                            variant="contained"
                                            className={classes.btnFilter}
                                            disabled={statusFrecuency.length === 0 ? true : false}
                                        >
                                            Aplicar
                                        </Button>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>

                {/* POPPER VALUE */}
                <Popper style={{ zIndex: 99 }} open={openValue} anchorEl={anchorRefValue.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                marginTop: 10,
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseValue}>
                                    <Grid style={{ height: 155 }}>
                                        <Grid style={{ display: 'flex', flexDirection: 'row', margin: 14 }}>
                                            <Grid style={{ marginTop: 14, marginRight: 10 }}>
                                                <Typography id="range-slider" gutterBottom>
                                                    Mínimo
                                                </Typography>
                                                <CurrencyTextField
                                                    variant="outlined"
                                                    value={selectedValue?.min}
                                                    currencySymbol="$"
                                                    minimumValue="0"
                                                    outputFormat="number"
                                                    decimalCharacter="."
                                                    digitGroupSeparator=","
                                                    onChange={(event, value) => setSelectedValue({ min: value, max: selectedValue?.max })}
                                                />
                                            </Grid>
                                            <Grid style={{ marginTop: 14, marginLeft: 10 }}>
                                                <Typography id="range-slider" gutterBottom>
                                                    Máximo
                                                </Typography>
                                                <CurrencyTextField
                                                    variant="outlined"
                                                    value={selectedValue?.max}
                                                    currencySymbol="&nbsp;$"
                                                    minimumValue="0"
                                                    outputFormat="number"
                                                    decimalCharacter="."
                                                    digitGroupSeparator=","
                                                    onChange={(event, value) => setSelectedValue({ min: selectedValue?.min, max: value })}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        <Button
                                            onClick={() => isApply(true)}
                                            style={{ margin: '6px 0px 0px 328px', width: 93 }}
                                            color="primary"
                                            variant="contained"
                                            className={classes.btnFilter}
                                            disabled={selectedValue?.min > selectedValue?.max || selectedValue?.max === 0 ? true : false}
                                        >
                                            Aplicar
                                        </Button>
                                    </Grid>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>

                {/* POPPER ORDER */}
                <Popper style={{ zIndex: 99 }} open={openOrder} anchorEl={anchorRefOrder.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                marginTop: 10,
                                transformOrigin: placement === 'bottom' ? 'right-end' : 'right-end',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseOrder}>
                                    <Grid>
                                        <Grid style={{ height: 190 }}>
                                            <Grid style={{ display: 'flex', flexDirection: 'row', margin: 14 }}>
                                                <Grid style={{ marginTop: 14, marginRight: 10 }}>
                                                    <Typography id="range-slider" gutterBottom>
                                                        Mínimo
                                                    </Typography>
                                                    <OutlinedInput
                                                        inputProps={{ min: 0, max: maxOrder, }}
                                                        onChange={(e) => {
                                                            setValue([Number(e?.target?.value), selectedOrder?.max])
                                                            setSelectedOrder({ min: Number(e?.target?.value), max: selectedOrder?.max })
                                                        }
                                                        }
                                                        type="number"
                                                        value={selectedOrder?.min}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid style={{ marginTop: 14, marginLeft: 10 }}>
                                                    <Typography id="range-slider" gutterBottom>
                                                        Máximo
                                                    </Typography>
                                                    <OutlinedInput
                                                        inputProps={{ min: 0, max: maxOrder, }}
                                                        onChange={(e) => {
                                                            setValue([selectedOrder?.min, Number(e?.target?.value)])
                                                            setSelectedOrder({ min: selectedOrder?.min, max: Number(e?.target?.value) })
                                                        }
                                                        }
                                                        type="number"
                                                        value={selectedOrder?.max}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Slider
                                                max={maxOrder}
                                                min={0}
                                                onChange={handleChange}
                                                value={value}
                                                valueLabelDisplay="auto"
                                            />
                                            <Divider />
                                            <Button
                                                onClick={() => isApply(true)}
                                                style={{ width: 93, marginLeft: 'auto' }}
                                                color="primary"
                                                variant="contained"
                                                className={classes.btnFilter}
                                                disabled={selectedOrder?.min > selectedOrder?.max || selectedOrder?.max === 0 ? true : false}
                                            >
                                                Aplicar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>

            </Grid>

            {
                rows.length === 0 && !gettingData
                    ?
                    <Grid item xs={12}>
                        <ViewDataEmpty title="Gestiona tus clientes"
                            subtitle="Aquí se mostrarán los clientes que realicen pedidos a través de tu tienda en línea. Tan pronto comiences a recibir pedidos, podrás obtener información detallada de tus clientes."
                            image={ImageDefaultClients}>
                        </ViewDataEmpty>
                    </Grid>
                    :
                    <React.Fragment>
                        {(rows.length >= 0 && !firstLoad) &&
                            <React.Fragment>
                                <Grid item xs={12} style={{ marginTop: 25 }}>
                                    <Paper className={classes.root}>
                                        <TableContainer className={isTabletOrMobile ? classes.container : classes.containerDesktop}>
                                            <div style={{ minHeight: '4px' }}>
                                                {gettingData && <LinearProgress />}
                                            </div>
                                            <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        {columns.map((column) => (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                style={{
                                                                    minWidth: column.minWidth,
                                                                    display: isTabletOrMobile ? column.mobile ? 'table-cell' : 'none' : 'table-cell',
                                                                }}>
                                                                {column.label}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rows.map((row, index) => {
                                                        return (
                                                            <>
                                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                    <TableCell>
                                                                        {`${row?.first_name} ${row?.last_name}`}
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        <Link
                                                                            onClick={() => handleSendWhatsapp(`${row?.first_name} ${row?.last_name}`, row?.phone_number)}
                                                                            color="textSecondary"
                                                                            variant='button'
                                                                        >
                                                                            {row?.phone_number}
                                                                        </Link>
                                                                    </TableCell>

                                                                    {/* <TableCell>
                                                                    {row?.lead}
                                                                </TableCell>

                                                                <TableCell>
                                                                    {row?.frequency}
                                                                </TableCell> */}

                                                                    <TableCell>
                                                                        {row?.quantity === 1 ? `${row?.quantity} pedido` : `${row?.quantity} pedidos`}
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        {`${formatNumber(row?.total)} ${storeData?.currency}`}
                                                                    </TableCell>

                                                                    <TableCell>
                                                                        <Button onClick={() => setClientToEdit(row)} color='primary'
                                                                            component={RouterLink} to={routes.clientPage + '/' + row.id} variant="contained">{t('ClientPage.headers.btnClient')}</Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            shape="rounded"
                                            variant="outlined"
                                            labelRowsPerPage={t('table.rowsPerPageLabel')}
                                            labelDisplayedRows={({ from, to, count }) => `${t('table.toShow')} ${to} ${t('table.of')} ${count} ${t('table.clients')}`}
                                            rowsPerPageOptions={totalRows > 0 ? [10, 25, 50] : []}
                                            component="div"
                                            count={totalRows}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                </Grid>
                            </React.Fragment>
                        }
                    </React.Fragment>
            }
        </Grid >
    );
}

const mapStateToProps = ({ auth, orders, common }) => ({
    storeId: auth.storeId,
    initialRowsPerPage: orders.rowsPerPage,
    initialPage: orders.page,
    storeData: common.storeData
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setRowsPerPageState,
    setPageState,
    setClientToEdit
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientsPage);