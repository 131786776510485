import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, CardHeader, MenuItem, Select } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useForm } from 'react-hook-form';
import { setOrderStatus } from '../../../services/orders';
import { getCitiesService } from '../../../services/misc'; 
import { useSnackbar } from 'notistack';
import { statusShipping } from '../../../constants.js/orders';
import EditOrderDataModal from '../EditOrderDataModal/EditOrderDataModal';
import { getStoreCountries } from '../../../services/billing';
import { setCountries } from '../../../modules/common';

const OrderShippingStatus = [
    {
        statusId: 7,
        statusName: "Preparado",
    },
    {
        statusId: 6,
        statusName: "No preparado",
    },
    {
        statusId: 8,
        statusName: "Parcialmente preparado",
    },
    {
        statusId: 9,
        statusName: "Programado",
    },
    {
        statusId: 10,
        statusName: "Despachado",
    }
]

const OrderShippingInfo = ({ countries, client, shipping, shippingStatus, comments, storeId, orderId, editMode, generalData, setGeneralData, setShippingData }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [changeState, setChangeState] = useState(false)
    const [city, setCity] = useState(null)
    const [country, setCountry] = useState(null)

    const otherCountries = shipping?.city_id === 688 ? true : false

    useEffect(() => {
        if (countries.length === 0) {
            getStoreCountries()
                .then(({ data }) => {
                    const find = data.find( c => c.code === shipping?.country_code)
                    setCountry(find)
                    setCountries(data)
                })
                .catch(err => { })
        }else{
            const find = countries?.find( c => c.code === shipping?.country_code)
            setCountry(find)
        }
    }, [countries])
    useEffect(() => {
        getCitiesService()
            .then(({ data })  => {
                const find = data.find( c => c.id === shipping.city_id)
                setCity(find)
            })
            .catch(err => console.log(err))
    }, [shipping])

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogClose = () => setDialogOpen(false);
    const handleDataUpdate = () => {
        handleDialogClose();
    }

    const openModal = () => setDialogOpen(true);

    const handleClick = () => {
        setChangeState(!changeState)
    }

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            newShippingState: shippingStatus || 8,
        }
    });

    // Submit
    const onSubmit = data => {
        setOrderStatus(storeId, orderId, data?.newShippingState, 2)
            .then(res => {
                enqueueSnackbar('Se actualizó correctamente', { variant: 'success' });
                window.location.reload();
            })
            .catch(err => {
                enqueueSnackbar('No se pudo actualizar', { variant: 'error' });
            })
    }

    const saveInfo = () => {
        setGeneralData({ ...generalData, ShippingState: watch('newShippingState') })
    }

    return (
        <>
            <Card elevation={2} style={{ marginBottom: '21px' }}>
                <CardHeader
                    titleTypographyProps={{ variant: 'h6' }}
                    title={t('OrderDetail.shippingTitle')}
                    style={{ padding: '20px 16px 13px 16px' }}
                />
                <CardContent style={{ padding: '0 16px 25px 16px' }}>
                    <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography gutterBottom variant="body1">
                            {t('OrderDetail.shipping.phone')} +{client?.phone_number}
                        </Typography>
                        <Typography gutterBottom variant="body1" style={{ textTransform: 'capitalize' }}>
                            {t('OrderDetail.shipping.name')} {client?.first_name} {client?.last_name}
                        </Typography>
                        <Typography gutterBottom variant="body1">
                            {t('OrderDetail.shipping.address')} {shipping?.address}
                        </Typography>
                        <Typography gutterBottom variant="body1">
                            {t('OrderDetail.shipping.address_detail')} {shipping?.address_detail}
                        </Typography>
                        { !otherCountries &&
                            <>
                                <Typography gutterBottom variant="body1">
                                    {t('OrderDetail.shipping.city')} {city?.name}
                                </Typography>
                                <Typography gutterBottom variant="body1">
                                    {t('OrderDetail.shipping.state')} {city?.state}
                                </Typography>
                            </>
                        }
                        <Typography gutterBottom variant="body1">
                            {t('OrderDetail.shipping.country')} {country?.name}
                        </Typography>
                        
                        {editMode &&
                            <Button
                                fullWidth
                                type='button'
                                variant="contained"
                                onClick={openModal}
                                color="gray">
                                {t('OrderDetail.shipping.editBtn')}
                            </Button>
                        }
                    </Grid>
                </CardContent>
                <CardContent style={{ padding: '0 16px 25px 16px' }}>
                    <Grid container style={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
                        <Typography gutterBottom variant="h6">
                            {t('OrderDetail.shippingStatusTitle')}
                        </Typography>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {!changeState && !editMode
                                ? <Typography variant="body1" style={{ fontWeight: 700, fontSize: '20px' }}>
                                    {statusShipping[shippingStatus]}
                                </Typography>
                                : <Select
                                    defaultValue={shippingStatus}
                                    {...register('newShippingState')}
                                    onClick={editMode && saveInfo}
                                    style={{ width: '47%' }}
                                    disableUnderline
                                >
                                    {OrderShippingStatus.map(status =>
                                        <MenuItem value={status.statusId}>
                                            {status.statusName}
                                        </MenuItem>
                                    )}
                                </Select>
                            }
                            {!editMode && <>
                                {!changeState
                                    ? <Button
                                        type='submit'
                                        style={{ marginTop: '16px', width: '70%' }}
                                        onClick={handleClick}
                                        variant="contained"
                                        color="primary">
                                        {t('OrderDetail.shippingStatusBtn')}
                                    </Button>
                                    : <Button
                                        type='button'
                                        style={{ marginTop: '16px', width: '70%' }}
                                        onClick={handleClick}
                                        variant="contained"
                                        color="primary">
                                        {t('OrderDetail.changeStatusBtnConfirm')}{statusShipping[watch('newShippingState')]}
                                    </Button>
                                }
                            </>}
                        </form>
                    </Grid>
                </CardContent>
            </Card>
            <EditOrderDataModal
                dataToEdit={{ ...shipping, comments }}
                setData={setShippingData}
                open={dialogOpen}
                isNoClose={false}
                handleClose={handleDialogClose}
                handleSuccess={handleDataUpdate}
                type={2}
            />
        </>
    );
};

const mapStateToProps = ({ common }) => ({
    countries: common.countries
});

export default connect(
    mapStateToProps
)(OrderShippingInfo);