

import styled from '@emotion/styled';

export const Content = styled.div`
  padding: 40px 0;
  max-width: 90%;
  flex: 1;
  ${props => props.isActive && `opacity: 0.5;pointer-events: 'none'; `}
`;


export const ContentSubTitle = styled.div`
  width: 90%;
  flex: 1;
  >p{
    margin-top: 10px;
  }
  margin-bottom: 2rem;
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;
  & > p {
    white-space: nowrap;
    fonz-size: .75rem;
  }
`;
