import React from 'react';
import ReactDOM from 'react-dom';
import Provider from './utils/Provider';

import store, { history } from './store';
import App from './App';
import '../src/index.css';
import * as serviceWorker from './serviceWorker';
// import { hotjar } from 'react-hotjar';
// import Favicon from 'react-favicon';
import { Helmet } from "react-helmet";

// if (process.env.NODE_ENV === 'production') {
//   hotjar.initialize(1891680, 6);
// }

ReactDOM.render(
  <Provider store={store} history={history}>
    <React.StrictMode>
      <Helmet>
        <meta name="google" content="notranslate" />
        <link rel="icon" href="/favicon.svg" />
      </Helmet>
      {/* <Favicon url="/favicon.ico" /> */}
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
