// import { grey } from "@material-ui/core/colors"

export const colors = {
    greyText: '#8181A5',
    greyBg: '#F0EFF2',
    brandColor: '#48AC98',
    white: '#ffffff',

    primary: {
        main: '#9EE870',
        dark: '#173300'
    },
    secondary: {
        main: '#1E1E1E',
    },
    gray: {
        main: '#F0EFF2',
        dark: '#8181A5',
    },
    text: {
        primary: '#1B2524',
        secondary: '#173300',
    }
};