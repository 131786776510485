import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { CardHeader } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';



const ProductInfoAdditional = ({ isTabletOrMobile, optionsInfoAdditional, setProductDataAdditional, onDeleteAdditional }) => {
    const { t } = useTranslation();

    const handleOptionDescriptionChange = ({ target }) => {
        setProductDataAdditional(optionsInfoAdditional.map((option, index) => ({
            ...option,
            description: index === +target.dataset.index ? target.value : option.description
        })));
    }

    const handleOptionNameChange = ({ target }) => {
        setProductDataAdditional(optionsInfoAdditional.map((option, index) => ({
            ...option,
            title: index === +target.dataset.index ? target.value : option.title
        })));
    }

    const onAddWindow = () => {
        setProductDataAdditional([...optionsInfoAdditional, {
            name: '', description: ''
        }])
    }


    return (
        <Card elevation={2} style={{ marginBottom: '21px' }}>
            <CardHeader titleTypographyProps={{ variant: 'h6' }} title={t('ProductInfoAdditional.titlePage')} />
            <CardContent>
                <Grid
                    container
                    spacing={isTabletOrMobile ? 0 : 3}>

                    {optionsInfoAdditional.map((option, index) => (
                        <React.Fragment key={index}>
                            {index !== 0 &&
                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        style={{ color: '#CB7C7A' }}
                                        color="inherit"
                                        size="small"
                                        onClick={() => onDeleteAdditional(index, option)}>
                                        {t('ProductInfoAdditional.deleteBtn')}
                                    </Button>
                                </Grid>
                            }
                            <Grid item xs={12} >
                                <FormControl>
                                    <FormLabel>{t('ProductInfoAdditional.title')}</FormLabel>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        value={option.title}
                                        onChange={handleOptionNameChange}
                                        placeholder={t('ProductInfoAdditional.titlePlaceholder')}
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{
                                            'data-index': index
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl>
                                    <FormLabel>{t('ProductInfoAdditional.description')}</FormLabel>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        value={option.description}
                                        onChange={handleOptionDescriptionChange}
                                        InputLabelProps={{ shrink: true }}
                                        multiline
                                        inputProps={{
                                            'data-index': index
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                        </React.Fragment>
                    ))}
                    {optionsInfoAdditional.length < 10 &&
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                onClick={onAddWindow}
                                style={{ marginTop: '16px' }}
                                variant="contained"
                                color="primary">
                                {t('ProductInfoAdditional.addBtn')}
                            </Button>
                        </Grid>
                    }
                </Grid>
            </CardContent>
        </Card>
    );
};
export default connect()(ProductInfoAdditional);