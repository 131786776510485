import React from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

import { ImgCustom } from './CardItemHome.styles';

export const CardItemHome = ({ image, title, subtitle, action, btnTitle }) => {
    return (
        <Card >
            <CardContent>
                <Grid container alignItems='center'>
                    <Grid item xs={9} >
                        <ImgCustom src={image}></ImgCustom>
                        <Typography color='textSecondary' variant="h6" style={{ marginBottom: '1rem', fontWeight: 'bold' }}>
                            {title}
                        </Typography>
                        <Typography variant="body2" style={{ width: '90%' }}>
                            {subtitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            component={Link}
                            to={action}
                            fullWidth
                            style={{ marginTop: '10%' }}
                            variant="contained"
                            color="primary">
                            {btnTitle}
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default CardItemHome;