import { Box, Button, Card, CardContent, Container, Dialog, Grid, Typography } from '@material-ui/core';
import React from 'react'
import { setShowWelcomePopup } from "../../../modules/common";
import { ButtonBox, CenteredContent } from './PopupFinishTrialPlan.styles';
import PaymentButton from './PaymenteButton/PaymentButton';

const PopupFinishTrialPlan = ({ open, disableEscapeKeyDown }) => {
  const handleDialogClose = () => {
    setShowWelcomePopup(false);
  };

  const handleRedirectToWhatsapp = () => {
    const sirenaNumber = "573003154556";
    const message = `Hola, necesito apoyo para realizar el pago de mi membresía`;
    window.open(
      `https://wa.me/${sirenaNumber}?text=${encodeURIComponent(message)}`
    );
  };

  return (
    <Dialog
      onClose={handleDialogClose}
      open={open}
      maxWidth="md"
      fullWidth={true}
      disableEscapeKeyDown={disableEscapeKeyDown || false}
    >
      <Box
        style={{
          backgroundColor: "#F7F8FE",
          padding: "30px 0 10px 0",
        }}
      >
        <Container maxWidth="md">
          <CenteredContent>
            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              {/* Título centrado */}
              <Typography
                variant="h2"
                gutterBottom
                align="center"
                color="secondary"
              >
                ¡Hola bienvenido a ecometri! <br/> Aún no tienes un plan activo
              </Typography>
              {/* Descripción */}
              <Typography variant="body1" gutterBottom align="center">
                El primer sistema Ecommerce para recibir pedidos <br /> por
                WhatsApp o por donde quieras.
              </Typography>
            </div>
          </CenteredContent>
          <CenteredContent>
            {/* Contenedor central */}
            <Card style={{ width: "95%" }}>
              <CardContent
                container
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "20px",
                  alignItems: "center",
                }}
              >
                {/* Texto */}
                <Grid item xs={12} md={8}>
                  <Typography variant="body1" gutterBottom>
                    Adquiere nuestro plan.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    0% de comisiones por venta, ni cobros ocultos de hostings o
                    dominios.
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Comprar:
                  </Typography>
                </Grid>
                {/* Botón */}
                <ButtonBox
                  component="section"
                  lg={12}
                  sm="auto"
                >
                  <PaymentButton plan={"anual"} />
                </ButtonBox>
                {/* Enlace */}
                <Grid item xs={12}>
                  <Button
                    underline="always"
                    color="secondary"
                    onClick={handleRedirectToWhatsapp}
                  >
                    <u>Necesito ayuda de un asesor para realizar el pago</u>
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </CenteredContent>
          <Grid style={{ height: "25px" }}></Grid>
        </Container>
      </Box>
    </Dialog>
  );
};

export default PopupFinishTrialPlan