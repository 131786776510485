// ReactJS
import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { useMediaQuery } from 'react-responsive';
import { Controller, useForm } from 'react-hook-form';

// Components
import ManageImportInventory from '../../components/ManageImportInventory/ManageImportInventory';
import Loader from '../../components/Loader';

// Services
import { importInventoryReview, importInventoryProcess, getInventory } from '../../services/inventory';

// Constants
import { routes } from '../../constants.js/misc';

// External dependencies
import { useSnackbar } from 'notistack';

// UI material dependencies
import {
    Button,
    Card,
    CircularProgress,
    Grid,
    Input,
    InputAdornment,
    LinearProgress,
    Link,
    OutlinedInput,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Internal components
import ViewDataEmpty from '../../components/ViewDataEmpty/ViewDataEmpty';

// Internal dependencies
import { errorToast } from '../../constants.js/misc';
import { setClientToEdit, setPageState, setRowsPerPageState } from '../../modules/clients';
import ImageDefaultInventory from '../../assets/img/default/EmptyInventory.png';
import CurrencyField from '../../components/CurrencyField/CurrencyField';

// Search
import { ProductImage } from '../ProductsTablePage/ProductsTablePage.styles';

// Assets
import SearchIcon from '../../assets/icons/SearchIcon.svg';
import SuccessIcon from '../../assets/icons/SuccessIcon.svg';

const useStyles = makeStyles({
    root: { width: '100%' },
    container: {
        maxHeight: '570px',
    },
    newContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
        marginBottom: '1rem'
    },
    containerDesktop: {
        maxHeight: '560px',
    },
    gridContainer: {
        "& div": {
            maxWidth: 'inherit',
            padding: '8px'
        },
    },
    btn: {
        "& .MuiButton-label": {
            fontSize: 14
        },
        "& .MuiButton-contained": {
            background: '#fff'
        }
    },
    btnFilter: {
        alignItems: 'flex-end',
        display: 'flex',
        justifyContent: 'center',
        margin: '8px 8px 0px 8px',

        "&:disabled": {
            backgroundColor: '#F6F6F6',
            color: '#8181A5',
        },
    },
    filterInput: {
        backgroundColor: '#fff',
        borderRadius: 8,
        boxShadow: '0px 0px 2px rgba(52, 58, 67, 0.1), 0px 1px 2px rgba(52, 58, 67, 0.08), 0px 1px 4px rgba(52, 58, 67, 0.08)',
        color: '#53627C',
        height: 42,
        padding: '14px 16px',
        width: '100%',
    },
    spacer: {
        "& .MuiTablePagination-spacer": {
            flex: 0
        }
    },
    notification: {
        backgroundColor: '#C1E9EB',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        padding: 16,
        borderRadius: 8,
        margin: '0px 0px 28px 0px',
    }
});

const InventoryImportPage = ({
    storeId,
    initialPage,
    initialRowsPerPage,
    setPageState,
    setRowsPerPageState,
    storeData,
    userEmail
}) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();

    // USESTATES
    const [gettingData, setGettingData] = useState(true);
    const [page, setPage] = useState(initialPage);
    const [rows, setRows] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
    const [totalRows, setTotalRows] = useState(0);
    const [firstLoad, setFirstLoad] = useState(true);
    const [apply, isApply] = useState(false);

    const [fileUpload, setFileUpload] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loadingLoadFile, setLoadingLoadFile] = useState(false);
    const [validFile, setValidFile] = useState(false);

    const [listErrors, setListErrors] = useState([]);

    const [successFile, setSuccessFile] = useState(false);
    const [items, setItemns] = useState();
    const [processId, setProcessId] = useState();

    const [filter, setFilter] = useState('');

    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);

    const columns = [
        { id: 'image', label: '', mobile: true },
        { id: 'name', label: t('InventoryPage.filter.name'), mobile: true },
        { id: 'sku', label: t('InventoryPage.filter.sku'), mobile: true },
        { id: 'available', label: t('InventoryPage.filter.available'), mobile: true },
    ];

    const {
        register,
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors }
    } = useForm({
        mode: 'all',
    });

    // HANDLECHANGE
    const handleChangePage = (e, newPage) => {
        setPage(newPage);
        setPageState(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setPageState(0);
        setRowsPerPage(+event.target.value);
        setRowsPerPageState(+event.target.value);
    };

    const uploadFileXlsx = () => {
        if (Object.keys(fileUpload).length > 0) {
            setLoadingLoadFile(true);

            const fileData = new FormData();

            fileData.append('file', fileUpload);

            importInventoryReview(storeId, fileData)
                .then(({ data }) => {
                    console.log(data);
                    setValidFile(true);
                    setLoadingLoadFile(false);
                    setItemns(data.items)
                    //rows.length === 0 ? setItemns(data.items)
                    //: setItemns(rows.map((item, index) => ({ ...item, quantity: data.items[index].quantity })));
                    setProcessId(data?.id);
                    setSuccessMessage(`¡Inventario subido correctamente! Estás importando ${data.items.length} productos con un total de ${data.items.length} SKUS. Previsualiza los productos para confirmar la carga masiva.`)
                    setGettingData(false);
                    setFirstLoad(false);
                })
                .catch(error => {
                    setValidFile(false);
                    setLoadingLoadFile(false);
                    setListErrors(error.response.data.errors);
                });
        }
    }

    const handleProcess = () => {
        setLoading(true)
        importInventoryProcess(storeId, processId, items)
            .then(({ data }) => {
                setLoading(false)
                setSuccess(true)
            })
            .catch(error => {
                console.log(error);
            });
    }

    const handleFilterChange = ({ target }) => {
        setFilter(target.value);
        setPage(0);
    };

    // CAPITALIZE
    const capitalize = (entry) => {
        return entry.charAt(0).toUpperCase() + entry.slice(1);
    }

    // USEEFFECTS
    useEffect(() => {
        setGettingData(true);
        isApply(false)
        getInventory(
            storeId, // -> storeId
            rowsPerPage, // -> limit
            page,  // -> page
            filter.replace(/\+/g, ' ').trim(),  // -> search
        )
            .then(res => {
                setTotalRows(+res.headers['x-total-count'] || 12);
                setRows(res.data.map(item => ({
                    ...item,
                    showProducts: false
                })));
            })
            .catch(err => {
                console.log('Error ClientPage', err);
                setGettingData(false);
                setFirstLoad(false);
                enqueueSnackbar(t('ClientPage.tableError'), errorToast)
            });
    }, [storeId, rowsPerPage, page, enqueueSnackbar, t, apply, filter])

    useEffect(uploadFileXlsx, [fileUpload]);

    return (
        <Grid item container xs={12} spacing={3}>
            <Grid item xs={6}>
                <Typography
                    variant="h4"
                    style={{ marginBottom: '10px' }}>
                    {t('ImportInventory.title')}
                </Typography>
                <Link
                    component={RouterLink}
                    to={routes.inventoryPage}
                >
                    {t('InventoryTitle')}
                </Link>
            </Grid>

            {!isSuccess
                ?
                isLoading
                    ?
                    <Fragment>
                        <Grid container xs={12} className={classes.header}>
                            <Card elevation={1} style={{ width: '100%' }}>
                                <Card elevation={0} style={{ margin: 27, backgroundColor: '#EFF3F7', padding: 22, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Loader />
                                    <Typography
                                        variant="body1"
                                        style={{ color: '#0F2930', fontSize: 18, margin: 10 }}
                                    >
                                        Importando inventario
                                    </Typography>
                                </Card>
                                <Card elevation={0} style={{ backgroundColor: '#F8F9FB' }}>
                                    <Typography
                                        variant="body1"
                                        style={{ color: '#53627C', fontSize: 16, margin: 10 }}
                                    >
                                        Puedes cerrar esta página, te enviaremos un correo cuando finalice la importación o consulta el estado de la importación en las notificaciones.
                                    </Typography>
                                </Card>
                            </Card>
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{ backgroundColor: '#CB7C7A', color: '#FFFFFF', marginTop: 10 }}
                                onClick={() => history.push('/inventory')}
                            >
                                Cancelar importación
                            </Button>
                        </Grid>
                    </Fragment>
                    :
                    successFile
                        ?
                        <Grid item xs={12} style={{ backgroundColor: '#fff', borderRadius: 8 }}>
                            <Typography variant="h5" style={{ marginBottom: 10 }}>
                                Previsualiza tu inventario
                            </Typography>
                            <Grid item container xs={6} style={{ justifyContent: 'flex-start', display: 'flex', marginBottom: 25 }}>
                                <Input
                                    className={classes.filterInput}
                                    disableUnderline
                                    color="primary"
                                    margin="normal"
                                    placeholder={t('InventoryPage.placeholderSearch')}
                                    onChange={handleFilterChange}
                                    value={filter}
                                    variant="outlined"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <img src={SearchIcon} alt="Search" />
                                        </InputAdornment>
                                    }
                                />
                            </Grid>
                            {items.length === 0
                                ?
                                <Grid item xs={12} style={{ marginTop: 25 }}>
                                    <ViewDataEmpty
                                        title="Gestiona tu inventario"
                                        subtitle="Con ecometri puedes rastrear tu inventario para evitar ventas de productos cuya existencia esté agotada. En este panel se mostrará el inventario de cada uno de los productos."
                                        image={ImageDefaultInventory}
                                        btnTitle={t('HomePage.productsBtn')}
                                        action="/products"
                                    >
                                    </ViewDataEmpty>
                                </Grid>
                                :
                                <Grid item xs={12}>
                                    {items.length >= 0 &&
                                        <React.Fragment>
                                            <Grid item xs={12} style={{ marginTop: 25 }}>
                                                <Paper className={classes.root}>
                                                    <TableContainer className={isTabletOrMobile ? classes.container : classes.containerDesktop}>
                                                        <div style={{ minHeight: '4px' }}>
                                                            {gettingData && <LinearProgress />}
                                                        </div>
                                                        <form>
                                                            <Table stickyHeader aria-label="sticky table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        {columns.map((column) => (
                                                                            <TableCell
                                                                                key={column.id}
                                                                                align={column.align}
                                                                                style={{
                                                                                    minWidth: column.minWidth,
                                                                                    display: isTabletOrMobile ? column.mobile ? 'table-cell' : 'none' : 'table-cell',
                                                                                }}>
                                                                                {column.label}
                                                                            </TableCell>
                                                                        ))}
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {items.map((row, index) => {
                                                                        return (
                                                                            <>
                                                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                                    <TableCell>
                                                                                        <ProductImage
                                                                                            style={{ maxWidth: '50px' }}
                                                                                            src={row?.product?.product_images[0]?.picture_url ? `${process.env.REACT_APP_IMAGES_API_URL}fit-in/600x600/${row?.product?.product_images[0]?.picture_url}` : `https://ui-avatars.com/api/?name=${row?.product?.name}&size=80&background=CAD7CA&color=fff`}
                                                                                            //src={`${process.env.REACT_APP_IMAGES_API_URL}/${Buffer.from(handleImageBase64()).toString('base64')}`}
                                                                                            alt={row?.product?.name}
                                                                                        />
                                                                                    </TableCell>

                                                                                    <TableCell>
                                                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                            <Link
                                                                                                onClick={() => console.log('click')}
                                                                                                component={RouterLink}
                                                                                                to={'/products/update/' + row?.name}
                                                                                            >
                                                                                                {row?.name}
                                                                                            </Link>
                                                                                        </div>
                                                                                    </TableCell>

                                                                                    <TableCell>
                                                                                        {row?.sku}
                                                                                    </TableCell>

                                                                                    <TableCell>
                                                                                        <OutlinedInput
                                                                                            {...register(`quantity-${row.id}`)}
                                                                                            disabled
                                                                                            defaultValue={row?.quantity}
                                                                                            inputProps={{ min: 0, max: 100 }}
                                                                                            type="number"
                                                                                            variant="outlined"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </>
                                                                        );
                                                                    })}
                                                                </TableBody>
                                                            </Table>
                                                        </form>
                                                    </TableContainer>
                                                    <TablePagination
                                                        shape="rounded"
                                                        variant="outlined"
                                                        labelRowsPerPage={t('table.rowsPerPageLabel')}
                                                        labelDisplayedRows={({ from, to, count }) => `${t('table.toShow')} ${to} ${t('table.of')} ${count} ${t('table.products')}`}
                                                        rowsPerPageOptions={totalRows > 0 ? [10, 25, 50] : []}
                                                        component="div"
                                                        className={classes.spacer}
                                                        count={totalRows}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onChangePage={handleChangePage}
                                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    />
                                                </Paper>
                                            </Grid>
                                        </React.Fragment>
                                    }
                                </Grid>
                            }
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                                <Button
                                    component={RouterLink}
                                    onClick={handleProcess}
                                    color="primary"
                                    variant="contained"
                                >
                                    Comenzar importación
                                </Button>
                            </Grid>
                        </Grid>
                        :
                        <Fragment>
                            <Grid item xs={12}>
                                <ManageImportInventory
                                    fileUpload={fileUpload}
                                    listErrors={listErrors}
                                    setFileUpload={setFileUpload}
                                    setListErrors={setListErrors}
                                    successMessage={successMessage}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                                <Button
                                    component={RouterLink}
                                    onClick={() => setSuccessFile(true)}
                                    color="primary"
                                    variant="contained"
                                    disabled={!fileUpload || loadingLoadFile || !validFile}
                                >
                                    {loadingLoadFile
                                        ? <CircularProgress size={30} color="secondary" />
                                        : t('ImportInventory.previewInventory')
                                    }
                                </Button>
                            </Grid>
                        </Fragment>
                :
                <Grid container xs={12} className={classes.header}>
                    <Card elevation={1} style={{ width: '100%' }}>
                        <Card elevation={0} style={{ margin: 27, backgroundColor: '#EFF3EF', padding: 22, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img src={SuccessIcon} alt="Success" />
                            <Typography
                                variant="body1"
                                style={{ color: '#53627C', fontSize: 18, margin: 10 }}
                            >
                                Tu inventario se importó correctamente. <span style={{ color: '#45C3AA', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => history.push('/inventory')}>Ver inventario.</span>
                            </Typography>
                        </Card>
                    </Card>
                </Grid>
            }
        </Grid>
    );
}

const mapStateToProps = ({ auth, orders, common }) => ({
    storeId: auth.storeId,
    userEmail: auth.userEmail,
    initialRowsPerPage: orders.rowsPerPage,
    initialPage: orders.page,
    storeData: common.storeData
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setRowsPerPageState,
    setPageState,
    setClientToEdit
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InventoryImportPage);