// ReactJS
import React from "react";
import { useHistory } from "react-router-dom";

// Other dependencies
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

// MUI Components
import {
    Button,
    Card,
    Grid,
    makeStyles,
    Typography,
} from "@material-ui/core";

// Icon [PNG]
import paymentImage from '../../../assets/img/paymentImage.png';

// Icon [SVG]
import WppIcon from '../../../assets/img/whatsapp-icon.png';
import iconPayment from '../../../assets/icons/iconPayment.svg';
import dragIcon from '../../../assets/icons/dragIcon.svg';

// Constants
import { routes } from "../../../constants.js/misc";

const useStyles = makeStyles({
    header: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '1rem',
        width: '100%',
    },
    cardList: {
        alignItems: 'center',
        display: 'flex',
        height: 112,
        justifyContent: 'space-between',
        margin: '2px 27px',
        padding: 20,
    }
});

const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const SectionPayment = ({ setPayment, configList, setConfigList }) => {
    const classes = useStyles();
    const history = useHistory();

    const onDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        if (
            source.index === destination.index &&
            source.droppableId === destination.droppableId
        ) {
            return;
        }

        setConfigList(reorder(configList, source.index, destination.index));
    }

    return (
        configList.length > 1
            ?
            <Grid container xs={12} md={10} xl={8} className={classes.header}>
                <Card elevation={1} style={{ width: '100%' }}>
                    <Typography
                        variant="body1"
                        style={{ color: '#53627C', fontSize: 24, margin: '25px 0px 25px 25px' }}
                    >
                        Formas de pago
                    </Typography>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="fields">
                            {(droppableProvided) => (
                                <div
                                    {...droppableProvided.droppableProps}
                                    className={classes.form}
                                    ref={droppableProvided.innerRef}
                                >
                                    {/* WhastApp Method */}
                                    <Card
                                        elevation={1}
                                        className={classes.cardList}
                                    >
                                        <img src={WppIcon} alt="iconPayment" style={{ marginRight: 4 }} />
                                        <Typography
                                            variant="body1"
                                            style={{ color: '#000000', fontSize: 16, width: '75%' }}
                                        >
                                            Recibe tus pedidos a través de WhatsApp.
                                        </Typography>
                                        <Button
                                            className="btnLink"
                                            style={{ backgroundColor: '#EEEEEE', color: '#53627C', fontSize: 18, borderRadius: 8 }}
                                            variant="contained"
                                            /* onClick={() => history.push(`${routes.addPaymentProviders}?provider=${method.name}`, method.name)} */
                                            onClick={() => history.push(`${routes.upgradePaymentProviders}`, {
                                                methodName: 'whatsapp',
                                                /* methodShow: payment_channel_id, */
                                            })}
                                        >
                                            Gestionar
                                        </Button>
                                    </Card>

                                    {configList.filter(m => m?.payment_channel?.name?.toLocaleLowerCase() !== 'whatsapp').map(({
                                        client_id,
                                        client_secret,
                                        name,
                                        payment_channel_id,
                                        payment_channel,
                                        extra_parameters,
                                        test_mode,
                                        integrity,
                                        event_wompi_key,
                                    }, index) => (
                                        <Draggable key={payment_channel_id} draggableId={payment_channel_id} index={index}>
                                            {(draggableProvided) => (
                                                <Card
                                                    {...draggableProvided.draggableProps}
                                                    {...draggableProvided.dragHandleProps}
                                                    ref={draggableProvided.innerRef}
                                                    elevation={1}
                                                    className={classes.cardList}
                                                    key={index}
                                                >
                                                    <img src={dragIcon} alt="" style={{ marginRight: 4 }} />
                                                    <img src={payment_channel.icon} alt="iconPayment" />
                                                    <Typography
                                                        variant="body1"
                                                        style={{ color: '#000000', fontSize: 16, width: '75%' }}
                                                    >
                                                        {payment_channel.description}
                                                    </Typography>
                                                    <Button
                                                        className="btnLink"
                                                        style={{ backgroundColor: '#EEEEEE', color: '#53627C', fontSize: 18, borderRadius: 8 }}
                                                        variant="contained"
                                                        /* onClick={() => history.push(`${routes.addPaymentProviders}?provider=${method.name}`, method.name)} */
                                                        onClick={() => history.push(`${routes.upgradePaymentProviders}`, {
                                                            methodId: payment_channel_id,
                                                            methodName: payment_channel?.name,
                                                            methodLabelAccountId: payment_channel?.client_id_label,
                                                            methodLabelApikey: payment_channel?.api_key_secret_label,
                                                            methodAccountId: client_id,
                                                            methodApikey: client_secret,
                                                            methodDescription: extra_parameters,
                                                            methodTestMode: test_mode,
                                                            integrity: integrity,
                                                            eventWompiKey: event_wompi_key
                                                        })}
                                                    >
                                                        Gestionar
                                                    </Button>
                                                </Card>
                                            )}
                                        </Draggable>
                                    ))}
                                    {droppableProvided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <Card elevation={0} style={{ margin: 27, backgroundColor: '#EFF3F7', padding: 22, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={iconPayment} alt="iconPayment" />
                        <Typography
                            variant="body1"
                            style={{ color: '#53627C', fontSize: 16, margin: 10, width: '40%', textAlign: 'center' }}
                        >
                            Selecciona una forma de pago de uno de los proveedores de pago aprobados de ecometri.
                        </Typography>
                        <Typography
                            variant="body1"
                            color="primary"
                            onClick={() => setPayment(true)}
                            style={{ fontSize: 16, margin: 10, cursor: 'pointer' }}
                        >
                            Agregar forma de pago
                        </Typography>
                    </Card>
                </Card>
            </Grid>
            :
            <Grid container xs={12} md={10} xl={8} className={classes.header}>
                <Card elevation={1} style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 60 }}>
                    <img src={paymentImage} alt="payment" />
                    <Typography
                        variant="body1"
                        style={{ color: '#0F2930', fontSize: 32, margin: '16px 16px 0px 16px', fontWeight: 700 }}
                    >
                        Agrega tus proveedores de pagos
                    </Typography>
                    <Typography
                        variant="body1"
                        style={{ color: '#0F2930', fontSize: 20, margin: 16 }}
                    >
                        Selecciona una forma de pago de uno de los proveedores de pago aprobados de ecometri.
                    </Typography>
                    <Button
                        className="btnLink"
                        color="primary"
                        variant="contained"
                        onClick={() => setPayment(true)}
                    >
                        Agregar forma de pago
                    </Button>
                </Card>
            </Grid>
    );
}

export default SectionPayment;