// ReactJS
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// Image [PNG]
import iconError from '../../assets/img/errorIcon.png';

// Icon [SVG]
import iconWhatsApp from "../../assets/icons/whiteWhatsApp.svg";

// MUI Components
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
    Typography,
} from '@material-ui/core';

// Components
import { CardContainer, IconCustom, TextContainer } from './CardItemConfig.styles';

export const CardItemConfig = ({ image, title, subtitle, action }) => {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <Card elevation={0}>
            <CardContent>
                <CardContainer style={{ textDecoration: 'none' }}>
                    <Link
                        onClick={() => setIsOpen(!isOpen)}
                        component={RouterLink}
                        to={action}
                    >
                        <IconCustom src={image}></IconCustom>
                    </Link>
                    <TextContainer>
                        <Link
                            color='textSecondary'
                            style={{ fontWeight: 'bold', marginBottom: '5px' }}
                            onClick={() => setIsOpen(!isOpen)}
                            component={RouterLink}
                            to={action}
                        >
                            {title}
                        </Link>
                        <Typography variant="body2" style={{ width:  '70%', textDecoration: 'none' }}>
                            {subtitle}
                        </Typography>
                    </TextContainer>
                </CardContainer>
            </CardContent>
        </Card>
    );
}

export default CardItemConfig;