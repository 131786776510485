import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';

import { colors } from '../../constants.js/colors';

export const AuthFormWrapper = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  animation: fadein 0.5s;
  position: relative;
`;

export const AuthFormAuxContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  padding: 12px;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const AuthFormAside = styled.div`
  height: calc(100vh - 24px);
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  border-radius: 16px;
`;

export const AuthFormMainContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  padding: 12px;
  width: 100%;
`;

export const AuthFormHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap 10px;
  height: 64px;
  width: 100%;
  margin-top: 20px;
  padding: 8px;
  >.container-flags {
    display: flex;
    column-gap 10px;
  }
`;

export const AuthFormContainer = styled.div`
  padding: 38px 0 0;
`;

export const MobileContent = styled.div`
  width: 100%;
  height: 100%;
  >img{
    margin: 0 auto;
    display: block;
    text-align: center;
  }
  h1{
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    color: #0F2930;
  }
  button{
    width: 100%;
  }
  display: none;
  @media screen and (max-width: 414px) {
    display: block;
  }
`;

export const MBHeader = styled.div`
    height: 320px;
    left: 0px;
    ${props => (props.isLogin ? 'background: #CAD7CA' : 'background: #C8D7E5')};
    margin-bottom: -15rem;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    >img{
      position: absolute;
      top: 66px;
      left: 0;
      right: 0;
      margin: auto;
    }
`;


export const ContentMB = styled.div`
    margin-top: 8rem;
    padding: 2rem;
`;

export const AuthFormContent = styled.div`
  overflow-y: auto;
  padding: 75px 160px;
  flex: 1;
`;

export const AuthFormSubtitle = styled(Typography)`
  color: ${colors.greyText};
  overflow-y: auto;
  padding-top: 10px;
  padding-bottom: 36px;
`;

